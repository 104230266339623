<template>
  <HeaderPages />
  <section class="section_contacts_pages">
    <div class="container_contacts_map">
      <p class="title_bid ml_1_5">КОНТАКТЫ</p>
      <div class="box_map">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A39527223e59e67f77ba3337c0a3d6b9af94440872b22bf5d533518f21a0f13ee&amp;source=constructor"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </div>
    <div class="containe_contacts">
      <div class="box_contacts">
        <a href="mailto:info@mti-dev.ru" class="title_bid">info@mti-dev.ru</a>
        <a href="tel:+79236922250" class="title_services_design"
          >+7 923 692 2250</a
        >
      </div>
      <div class="box_contacts_address">
        <div class="box_city">
          <p class="title_services_design">Санкт-Петербург</p>
          <div class="box_index">
            <p class="text_small_kreadon-bold color_violet">196158</p>
            <p class="text_small_kreadon-bold">Пулковском шоссе, 14Е</p>
          </div>
        </div>
        <div class="box_href_social">
          <p class="text_small_kreadon-bold text_align_right">youtube</p>
          <p class="text_small_kreadon-bold text_align_right">telegram</p>
          <p class="text_small_kreadon-bold text_align_right">vkontakte</p>
        </div>
      </div>
    </div>
    <div class="box_map_v2">
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A39527223e59e67f77ba3337c0a3d6b9af94440872b22bf5d533518f21a0f13ee&amp;source=constructor"
        width="100%"
        height="100%"
        frameborder="0"
      ></iframe>
    </div>
  </section>
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import { useHead } from "@vueuse/head";
export default {
  components: { HeaderPages, FooterPages },
  setup() {
    useHead({
      title: "Контакты - MTI-Dev",
      meta: [
        {
          name: "description",
          content:
            "Свяжитесь с нами для консультации и заказа услуг. Контакты для связи: телефон, email и адрес офиса. Мы всегда готовы ответить на ваши вопросы и помочь вашему бизнесу",
        },
      ],
    });
  },
};
</script>
<style scoped>
.section_contacts_pages {
  background: linear-gradient(
    -5.71deg,
    rgba(219, 242, 255, 0) 31.898%,
    rgb(228, 241, 255) 64.452%,
    rgb(255, 235, 254) 98.175%
  );
  padding: 12.7rem 4.5rem 10rem 4.5rem;
  display: flex;
  gap: 6rem;
}
.container_contacts_map {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.box_map {
  border-radius: 1.7rem;
  width: 31.5rem;
  height: 17.75rem;
  overflow: hidden;
  box-shadow: 0.7rem 0.7rem 2rem -0.8rem rgba(71, 86, 163, 0.4);
}
.ml_1_5 {
  margin-left: 1.5rem;
}
.containe_contacts {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 1.5rem 0px;
  justify-content: space-between;
}
.box_contacts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.box_contacts_address {
  display: flex;
  gap: 5rem;
}
.box_index {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.box_city {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
}
.box_href_social {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-end;
}
.box_map_v2 {
  display: none;
}
@media (max-width: 767px) {
  .section_contacts_pages {
    flex-direction: column;
  }
  .box_map {
    display: none;
  }
  .ml_1_5 {
    margin-left: 0px;
    margin-bottom: 3.5rem;
  }
  .section_contacts_pages {
    padding: 10rem 1.9rem 2rem 1.9rem;
    gap: 2rem;
  }
  .box_map_v2 {
    display: block;
    width: 30.45rem;
    height: 45.45rem;
    border-radius: 1.64rem;
    box-shadow: 1.27rem 1.27rem 3.64rem -1.45rem rgba(71, 86, 163, 0.4);
    overflow: hidden;
  }
  .box_contacts_address {
    flex-direction: column;
    gap: 3.5rem;
  }
  .box_href_social {
    flex-direction: row;
    justify-content: flex-start;
    gap: 3.5rem;
  }
  .containe_contacts {
    padding: 0px;
    gap: 3.5rem;
  }
  .box_contacts {
    gap: 1rem;
  }
}
</style>
