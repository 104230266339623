<template>
  <section class="section_popular_articles">
    <div class="wave_down_v3"></div>
    <div class="container_popular_articles">
      <p class="big_text color_white">ПОПУЛЯРНЫЕ СТАТЬИ</p>
      <div class="container_all_popular_articles">
        <router-link
          to="/Stages_of_website_development_complete_guide_from_idea_to_launch"
          class="popular_articles"
        >
          <p class="title_services_design text_align_left color_violet">
            12 мин. &nbsp; &nbsp;
            <img class="img_eye" src="@/assets/elements/eye.svg" />
            724
          </p>
          <p class="title_services_design text_align_left color_white">
            Важные шаги в доработке уже существующего сайта
          </p>
        </router-link>
        <router-link
          to="/Important_steps_in_refining_an_existing_website"
          class="box_popular_articles_right"
        >
          <div class="popular_articles">
            <p class="title_services_design text_align_left color_violet">
              15 мин. &nbsp; &nbsp;<img
                class="img_eye"
                src="@/assets/elements/eye.svg"
              />
              657
            </p>
            <p class="title_services_design text_align_left color_white">
              Этапы разработки сайта: полное руководство
            </p>
          </div>
        </router-link>
        <router-link
          to="/Multi_page_site_or_one_page_site"
          class="popular_articles"
        >
          <p class="title_services_design text_align_left color_violet">
            10 мин. &nbsp; &nbsp;<img
              class="img_eye"
              src="@/assets/elements/eye.svg"
            />
            630
          </p>
          <p class="title_services_design text_align_left color_white">
            Многостраничный сайт или одностраничный: что лучше для вашего
            бизнеса?
          </p>
        </router-link>
      </div>
    </div>
  </section>
  <section class="section_briefing">
    <BriefingSection />
  </section>
  <footer class="section_footer">
    <div class="container_footer">
      <div class="box_button_footer">
        <!-- <p class="subtext_services_design color_violet">
          Презентация об агентстве
        </p> -->
        <a href="https://mti-dev.ru/privacy_policy.pdf" target="_blank" class="subtext_services_design color_violet underline">
          Политика конфиденциальность
        </a>
      </div>
      <p class="subtext_services_design color_violet">© 2019-2024, MTI-Dev</p>
    </div>
  </footer>
</template>
<script>
import BriefingSection from "./BriefingSection.vue";
export default {
  components: {
    BriefingSection,
  },
};
</script>
