<template>
  <BidForm />
  <div ref="boxButton" class="box_button_all">
    <button data-action="toggleBig" class="button_v1">Оставить заявку</button>
  </div>
  <header>
    <div @mousemove="onMouseMove" ref="header_section" class="header_section">
      <div ref="container_header" class="container_header">
        <RouterLink to="/"
          ><img class="logo_header" src="@/assets/logo/logo.svg"
        /></RouterLink>
        <div ref="box_button_nav_header" class="box_button_nav_header">
          <RouterLink to="/all_project" class="text_small_kreadon-bold"
            >проекты</RouterLink
          >
          <RouterLink to="/blog" class="text_small_kreadon-bold"
            >блог</RouterLink
          >
          <RouterLink to="/services" class="text_small_kreadon-bold"
            >услуги</RouterLink
          >
          <RouterLink to="/contacts" class="text_small_kreadon-bold"
            >контакты</RouterLink
          >
        </div>
        <div class="box_button_nav">
          <div class="box_button_burger" @click="toggleModal">
            <div ref="button_burger_line_1" class="button_burger_line_1"></div>
            <div ref="button_burger_line_2" class="button_burger_line_2"></div>
            <div ref="button_burger_line_3" class="button_burger_line_3"></div>
          </div>
          <div class="box_button_v1_header">
            <button
              v-if="!isHomePage"
              data-action="toggleBig"
              class="button_v1"
            >
              Оставить заявку
            </button>
          </div>
          <div class="box_button_eng_header">
            <div class="button_eng_header">eng</div>
          </div>
        </div>
      </div>
      <div ref="content_burger" class="content_burger">
        <div class="left_nav_burger">
          <div class="box_nav_burger">
            <!-- <RouterLink
              to="/"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >о mti dev</RouterLink
            > -->
            <RouterLink
              to="/all_project"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >проекты</RouterLink
            >
            <RouterLink
              to="/blog"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >блог</RouterLink
            >
            <RouterLink
              to="/services"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >услуги</RouterLink
            >
            <RouterLink
              to="/contacts"
              @click="closeModal"
              class="text_burger_nav text_align_right"
              >контакты</RouterLink
            >
          </div>
          <!-- <img class="search_button" src="@/assets/elements/search.svg" /> -->
        </div>
        <div class="right_nav_burger">
          <p class="text_small_kreadon-bold">youtube</p>
          <p class="text_small_kreadon-bold">telegram</p>
          <p class="text_small_kreadon-bold">vkontakte</p>
          <div class="box_box_animation" @mousemove="onMouseMove">
            <img
              ref="face_bot_burger"
              class="face_bot_burger"
              src="@/assets/elements/face_mti.png"
            />
            <img class="body_bot_burger" src="@/assets/elements/body_mti.png" />
          </div>
        </div>
        <div class="box_button_burger_mobil">
          <div @click="closeModal" class="box_button_v2">
            <button data-action="toggleBig" class="button_v1">
              Оставить заявку
            </button>
          </div>
          <div class="box_button_eng_burger">
            <div class="button_eng_header">eng</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import BidForm from "./BidForm.vue";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  components: {
    BidForm,
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      face_bot_burger: null,
      maxRight: 1.9,
      minRight: 1.5,
      maxTop: 0.7,
      minTop: 0.2,
    };
  },
  mounted() {
    this.face_bot_burger = this.$refs.face_bot_burger;
    gsap.registerPlugin(ScrollTrigger);

    // Анимация появления
    gsap.fromTo(
      this.$refs.boxButton,
      {
        opacity: 0, // Изначально скрыта
        y: -50, // Позиция выше экрана
      },
      {
        opacity: 1, // Появление
        y: 0, // Нормальная позиция
        duration: 0.5,
        scrollTrigger: {
          trigger: document.body, // Триггер — весь документ
          start: () => `${window.innerHeight}px top`, // Начало после 100vh
          toggleActions: "play reverse play reverse", // Анимация вниз/вверх
        },
      }
    );
  },
  methods: {
    toggleModal() {
      this.$refs.box_button_nav_header.classList.toggle("active");
      this.$refs.content_burger.classList.toggle("active");
      this.$refs.header_section.classList.toggle("active");
      this.$refs.container_header.classList.toggle("active");
      this.$refs.button_burger_line_1.classList.toggle("active");
      this.$refs.button_burger_line_2.classList.toggle("active");
      this.$refs.button_burger_line_3.classList.toggle("active");
      document.body.classList.toggle("no-scroll");
    },
    closeModal() {
      this.$refs.box_button_nav_header.classList.toggle("active");
      this.$refs.content_burger.classList.remove("active");
      this.$refs.header_section.classList.remove("active");
      this.$refs.container_header.classList.remove("active");
      this.$refs.button_burger_line_1.classList.remove("active");
      this.$refs.button_burger_line_2.classList.remove("active");
      this.$refs.button_burger_line_3.classList.remove("active");
      document.body.classList.remove("no-scroll");
    },
    onMouseMove(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    toggleBig() {
      this.$refs.section_briefing.classList.toggle("active");
      document.body.classList.toggle("no-scroll");
    },
    closeBig() {
      this.$refs.section_briefing.classList.toggle("active");
      document.body.classList.remove("no-scroll");
    },
  },
  watch: {
    mouseX(mouseX) {
      // Используйте mouseX напрямую
      const right =
        this.maxRight -
        (mouseX / window.innerWidth) * (this.maxRight - this.minRight);
      this.face_bot_burger.style.right = `${right}rem`;
    },
    mouseY(mouseY) {
      // Используйте mouseY напрямую
      const top =
        this.minTop +
        (mouseY / window.innerHeight) * (this.maxTop - this.minTop);
      this.face_bot_burger.style.top = `${top}rem`;
    },
  },
  computed: {
    // Определяем, является ли текущая страница главной
    isHomePage() {
      return this.$route.path === "/"; // '/' — маршрут главной страницы
    },
  },
};
</script>
<style scoped>
.box_button_all {
  position: fixed;
  top: 1rem;
  right: 8.5rem;
  opacity: 0; /* Изначально скрыта */
  transform: translateY(-50px); /* Смещение вверх */
  z-index: 3;
  width: 12rem;
  height: 3.5rem;
  transition: transform 0.3s ease-out;
}
@media (max-width: 767px) {
  .box_button_all {
    z-index: 2;
    width: 60%;
    left: 20%;
  }
}
</style>
