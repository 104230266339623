<template>
  <section class="section_blog_home_pages">
    <div class="container_blog_home_pages">
      <div class="box_title_services_v2">
        <p class="title_services_design text_align_left color_violet">Блог</p>
      </div>
      <div class="container_all_blog_home_pages">
        <router-link
          v-for="(article, index) in articles"
          :key="index"
          class="box_blog_home_pages"
          :to="article.path"
        >
          <p class="subtext_services_design color_violet">{{ article.date }}</p>
          <p class="subtext_services_design">{{ article.text }}</p>
        </router-link>
      </div>
      <div class="container_button_circle_v2">
        <div class="box_button_v1" ref="buttonCircle">
          <router-link to="/blog" class="button_v1"
            >БОЛЬШЕ ПУБЛИКАЦИЙ</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";

export default {
  data() {
    return {
      articles: [
        {
          date: "24 ноября 2024",
          text: "Ваш сайт уже создан, но его работа далека от идеала? Он не приносит ожидаемых результатов, медленно загружается, или пользователи испытывают трудности с навигацией? ",
          path: "/Important_steps_in_refining_an_existing_website",
        },
        {
          date: "22 ноября 2024",
          text: "Каждый сайт уникален, и выбор его формата зависит от целей и задач вашего бизнеса. Многостраничные сайты удобны для масштабных проектов, а одностраничные сайты — идеальны для",
          path: "/Multi_page_site_or_one_page_site",
        },
        {
          date: "18 ноября 2024",
          text: "Создание интернет-магазина под ключ становится всё более популярным среди предпринимателей, которые хотят вывести свои продажи на новый уровень.",
          path: "/How_a_turnkey_online_store_will_increase_your_sales",
        },
      ],
    };
  },
  mounted() {
    const containerBlogElement = document.querySelector(
      ".container_blog_home_pages"
    );
    const blogArticlesElements = document.querySelectorAll(
      ".box_blog_home_pages"
    );
    const buttonCircleElement = this.$refs.buttonCircle;

    const tl = gsap.timeline({ paused: true });

    const onIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        tl.play();
        containerBlogElement.removeEventListener(
          "intersection",
          onIntersection
        );
      }
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });

    observer.observe(containerBlogElement);

    blogArticlesElements.forEach((targetElement) => {
      tl.set(targetElement, { y: "5rem", opacity: 0 });
      tl.to(targetElement, {
        y: "0rem",
        opacity: 1,
        duration: 0.4,
      });
    });

    // Анимация для кнопки
    tl.set(buttonCircleElement, { y: "5rem", opacity: 0 });
    tl.to(buttonCircleElement, {
      y: "0rem",
      opacity: 1,
      duration: 0.5,
    });
  },
};
</script>

<style scoped>
.box_blog_home_pages {
  /* Добавьте стили для начального состояния, если нужно */
  opacity: 0;
}

.box_button_v1 {
  width: 18.8rem;
  opacity: 0;
}
.container_button_circle_v2 {
  justify-content: center;
}
@media (max-width: 767px) {
  .box_button_v1 {
    width: 18.8rem;
    width: 19.55rem;
  }
  .button_v1 {
    font-size: 1.27rem;
    line-height: 1.36rem;
  }
}
</style>
