<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">БЛОГ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_main_info_project">
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="font_2_4_700_Kreadon">2023-2024</p>
      </div>
      <div class="box_main_info_project_right">
        <p class="font_2_4_700_Kreadon">
          Наименование<br />
          компании
        </p>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">
          Аналитика<br />
          UX/UI дизайн<br />
          Frontend
          <br />
        </p>
      </div>
      <div class="box_main_info_project_right">
        <p class="subtext_services_design">
          Краткое описание клиента при необходимости
        </p>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left"></div>
      <div class="box_main_info_project_right">
        <button class="button_light">Сайт</button>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">Цели</p>
      </div>
      <div class="box_main_info_project_right">
        <p class="subtext_services_design">
          Задача, поставленная перед командой разработчиков Задача, поставленная
          перед командой разработчиков
        </p>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">Задачи</p>
      </div>
      <div class="box_main_info_project_right">
        <ul class="list_articles subtext_services_design">
          <li>
            Разработать систему электронной отчетности (реестр) по выбросам СО2.
          </li>
        </ul>
        <ul class="list_articles subtext_services_design">
          <li>
            Автоматизировать бизнес-процессы по работе с информацией о выбросах
            парниковых газов: подачу и проверку отчетов, аналитику данных.
          </li>
        </ul>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">Решение</p>
      </div>
      <div class="box_main_info_project_right">
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular color_violet">01</p>
          <p class="subtext_services_design">
            Задача, поставленная перед командой разработчиков Задача,
            поставленная перед командой разработчиков
          </p>
        </div>
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular color_violet">02</p>
          <p class="subtext_services_design">
            Построить систему рейтингов на основе оценок и отзывов от партнеров
            и заказчиков.
          </p>
        </div>
      </div>
    </div>
    <div class="container_slaider_project">
      <button class="custom-prev">
        <img
          class="arrow_left_slider"
          src="@/assets/elements/arrow_right.svg"
        />
      </button>

      <button class="custom-next">
        <img
          class="arrow_right_slider"
          src="@/assets/elements/arrow_right.svg"
        />
      </button>
      <swiper
        :modules="modules"
        :slides-per-view="1"
        loop="true"
        class="mySwiper"
        :navigation="{
          nextEl: '.custom-next',
          prevEl: '.custom-prev',
        }"
      >
        <swiper-slide v-for="(slide, index) in slides" :key="index">
          <img
            src="@/assets/background/test_slaider.png"
            class="slide-content"
          />
        </swiper-slide>
      </swiper>
    </div>

    <div class="container_main_info_project">
      <div class="box_main_info_project_left"></div>
      <div class="box_main_info_project_right">
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular color_violet">03</p>
          <p class="subtext_services_design">
            Информация о содержании слайдов и др. будет помещаться справа.
            Отступ между циферными строками “01, 02, 03...”
          </p>
        </div>
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular color_violet">04</p>
          <p class="subtext_services_design">
            Отступ между циферной строкой и строкой под “точкой” равен 40
          </p>
        </div>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left"></div>
      <div class="box_main_info_project_right">
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular">-</p>
          <p class="subtext_services_design">
            Автоматизировать бизнес-процессы по работе с информацией о выбросах
            парниковых газов: подачу и проверку отчетов, аналитику данных.
            Междустрочный отступ в одной строке равен 26
          </p>
        </div>
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular">-</p>
          <p class="subtext_services_design">
            Отступ между строк под “точками” равен 20
          </p>
        </div>
      </div>
    </div>
    <div class="container_img_project">
      <img class="img_project" src="@/assets/background/test.png" />
      <img class="img_project" src="@/assets/background/test.png" />
      <img class="img_project" src="@/assets/background/test.png" />
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">Результат</p>
      </div>
      <div class="box_main_info_project_right">
        <div class="flex_gap_0_45">
          <p class="font_1_400_Kreadon_Regular">-</p>
          <p class="subtext_services_design">
            Для компаний-подрядчиков такая платформа — это возможность узнать о
            своих сильных и слабых сторонах и улучшить качество сервиса. А для
            заказчиков — возможность понять, на что стоит обратить особое
            внимание при работе с новым подрядчиком.
          </p>
        </div>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left"></div>
      <div class="box_main_info_project_right">
        <div class="flex_space_between">
          <p class="subtext_services_design">
            <span class="font_2_4_700_Kreadon color_violet">10000</span>
            <BR />
            Рейтинги
          </p>
          <p class="subtext_services_design">
            <span class="font_2_4_700_Kreadon color_violet">10000</span>
            <BR />
            Рейтинги
          </p>
        </div>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left">
        <p class="subtext_services_design">Команда</p>
      </div>
      <div class="box_main_info_project_right_row">
        <ul class="ul_team subtext_services_design">
          <span class="color_violet">Команда</span>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
        </ul>
        <ul class="ul_team subtext_services_design">
          <span class="color_violet">Команда</span>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
        </ul>
      </div>
    </div>
    <div class="container_main_info_project">
      <div class="box_main_info_project_left"></div>
      <div class="box_main_info_project_right_row">
        <ul class="ul_team subtext_services_design">
          <span class="color_violet">Команда</span>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
        </ul>
        <ul class="ul_team subtext_services_design">
          <span class="color_violet">Команда</span>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
          <li>Имя Фамилия</li>
        </ul>
      </div>
    </div>
  </section>
  <SectionBlog />
  <SectionProjects />
  <FooterPages />
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionBlog from "../ui/SectionBlog.vue";
import SectionProjects from "../ui/SectionProjects.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    HeaderPages,
    FooterPages,
    SectionBlog,
    SectionProjects,
  },
  setup() {
    const slides = [1, 2, 3, 4, 5];

    return {
      slides,
      modules: [Navigation],
    };
  },
};
</script>
<style scoped>

</style>
