<template>
  <HeaderPages />
  <section class="section_article">
    <div class="container_article_left">
      <div class="main_block_article">
        <p class="font_1rem_400_Raleway color_violet">10 июля 2024</p>
        <p class="title_services_design">
          Форматно-логический контроль. Зачем он нужен бизнесу и как его
          систематизировать.
        </p>
        <img
          class="illustrations_articles"
          src="@/assets/background/articles.png"
        />
        <div class="autor_mobile">
          <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
          <!-- <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                Frontend-разработчик
              </p>
            </div>
          </div> -->
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
        <p class="font_1_2_500_Raleway">
          Данные правят бизнесом: направляют стратегии, формируют решения и
          определяют успех. С ростом их объемов компании все чаще сталкиваются с
          проблемой качества.<br />
          В этом случае потери могут быть существенные — от испорченной
          репутации до финансовых убытков.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">Плюсы форматно-логического контроля</p>
      <div class="box_text_articles">
        <p class="font_0_9_400_Raleway">
          Форматно-логический контроль (ФЛК) — процесс проверки данных на
          соответствие заданным параметрам и логическим правилам: выявление и
          исправление ошибок, таких как неправильные форматы, дубликаты,
          пропущенные значения или несоответствия.
        </p>
        <p class="font_0_9_400_Raleway">
          Его основная цель — обеспечить высокое качество и корректность
          информации. Для компаний, которые делают ставку на качество, внедрение
          ФЛК будет конкурентным преимуществом.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">Плюсы форматно-логического контроля</p>
      <div class="box_text_articles">
        <p class="font_1_2_500_Raleway">Повышение качества данных</p>
        <p class="font_0_9_400_Raleway">
          Его основная цель — обеспечить высокое качество и корректность
          информации. Для компаний, которые делают ставку на качество, внедрение
          ФЛК будет конкурентным преимуществом.
        </p>
      </div>
      <div class="box_text_articles">
        <p class="font_1_2_500_Raleway">Оптимизация</p>
        <p class="font_0_9_400_Raleway">
          Форматно-логический контроль играет ключевую роль в управлении
          рабочими процессами. Когда данные соответствуют заданным форматам,
          многие задачи можно автоматизировать. А это значительно ускоряет
          работу.
        </p>
        <p class="font_0_9_400_Raleway">
          Например, логистическая компания DHL внедрила систему контроля данных,
          позволившее сократить время обработки заказов и повысить точность
          доставки. Здесь для бизнеса можно выделить два плюса:
        </p>
        <p class="font_0_9_400_Raleway">
          1. Улучшение обслуживания пользователей. <br />
          2. Снижение операционных затрат.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">Плюсы форматно-логического контроля</p>
      <p class="font_0_9_400_Raleway">
        Если говорить совсем просто, то различные документы оцениваются на
        соответствие установленным форматам и правилам. Это также включает
        проверку общего оформления и логики.
      </p>
      <div class="box_text_articles">
        <div class="container_table_articles">
          <div class="box_table_articles_top">
            <div class="box_table_articles_small">
              <p class="font_0_9_400_Raleway">Тип документов</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">Примеры</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">Область проверки</p>
            </div>
          </div>
          <div class="box_table_articles_center">
            <div class="box_table_articles_small">
              <p class="font_0_9_400_Raleway">Тип документов</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Чеки, квитанции, налоговые декларации
              </p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Наличие обязательных реквизитов, корректность сумм и
                соответствие законодательным нормам
              </p>
            </div>
          </div>
          <div class="box_table_articles_center bg_grey">
            <div class="box_table_articles_small">
              <p class="font_0_9_400_Raleway">Тип документов</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Чеки, квитанции, налоговые декларации
              </p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Наличие обязательных реквизитов, корректность сумм и
                соответствие законодательным нормам
              </p>
            </div>
          </div>
          <div class="box_table_articles_center">
            <div class="box_table_articles_small">
              <p class="font_0_9_400_Raleway">Тип документов</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Чеки, квитанции, налоговые декларации
              </p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Наличие обязательных реквизитов, корректность сумм и
                соответствие законодательным нормам
              </p>
            </div>
          </div>
          <div class="box_table_articles_center bg_grey">
            <div class="box_table_articles_small">
              <p class="font_0_9_400_Raleway">Тип документов</p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Чеки, квитанции, налоговые декларации
              </p>
            </div>
            <div class="box_table_articles_big">
              <p class="font_0_9_400_Raleway">
                Наличие обязательных реквизитов, корректность сумм и
                соответствие законодательным нормам
              </p>
            </div>
          </div>
        </div>
        <p class="font_0_9_400_Raleway">Договоры, соглашения</p>
      </div>
      <div class="box_text_articles">
        <p class="font_1_2_500_Raleway">Кейс 1. Фискальный чек</p>
        <ul class="list_articles font_0_9_400_Raleway">
          <p class="font_700 -m_1rem">На что смотрим</p>
          <li>
            Формат данных — дата и время покупки, наименование товаров, их
            количество, цена, общая сумма, налоговая информация.
          </li>
          <li>
            Логика документа — корректное применение налоговых ставок, сумма
            всех позиций должна совпадать с итоговой.
          </li>
        </ul>
        <p class="font_0_9_400_Raleway">
          <span class="font_700">Пояснение:</span><br />
          Если в счете-фактуре указаны неверные реквизиты или суммы, это может
          привести к отказу в принятии документа Федеральной налоговой службой
          (ФНС) или контрагентами.
        </p>
      </div>
      <div class="box_wave_articles">
        <p class="font_1_2_500_Raleway">Учили, учили и научили</p>
        <p class="font_0_9_400_Raleway">
          Действительно, обучение сотрудников играет ключевую роль в успешном
          внедрении форматно-логического контроля. В России во многих крупных
          компаниях существуют подобные практики, так как регулярные тренинги и
          семинары помогают понять важность точных данных и работать с
          инструментами контроля. Так, культура «внимательности» часто
          становится неотъемлемой частью корпоративной.
        </p>
        <img
          class="wave_small_articles"
          src="@/assets/background/wave_small_articles.svg"
        />
      </div>
    </div>
    <div class="container_article_right">
      <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
      <div class="box_autor_article">
        <div class="autor_article_first">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div>
        <!-- <div class="autor_article_first m_autor_article">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div> -->
        <div ref="box_all_autor_article" class="box_all_autor_article">
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_article_bottom">
    <div class="container_article_bottom">
      <div class="box_back_article">
        <button class="button_back_article">
          <img
            src="@/assets/elements/arrow_left.svg"
            class="arrow_left_article"
          />
        </button>
        <p class="text_small_kreadon-bold color_violet">
          вернуться к списку статей
        </p>
      </div>
    </div>
  </section>
  <FooterArticles />
</template>
<script>
import { useHead } from "@vueuse/head";
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterArticles from "@/components/permanent/FooterArticles.vue";

export default {
  components: { HeaderPages, FooterArticles },
  data() {
    return {
      isModalOpen: false, // Состояние модального окна
      openImageSrc: require("@/assets/elements/arrow_bottom_white.svg"),
      closeImageSrc: require("@/assets/elements/arrow_top_white.svg"),
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen; // Переключение состояния
      this.$refs.box_all_autor_article.classList.toggle("active");
    },
    closeModal() {
      this.isModalOpen = false; // Сброс состояния
      this.$refs.box_all_autor_article.classList.remove("active");
    },
  },
  setup() {
    useHead({
      title: "Тут пишешь тайтл",
      meta: [
        {
          name: "description",
          content: "тут дискрипшин",
        },
      ],
    });
  },
};
</script>
<style scoped></style>
