<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">БЛОГ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button data-action="toggleBig" class="button_v1">
            Оставить заявку
          </button>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="section_button_blog">
    <div class="mobail_version">
      <button class="button_filter_open" @click="toggleFilter(1)">
        Отрасли
        <div class="box_img_bitton_open">
          <img
            :src="isFilterOpen === 1 ? closeImageSrc : openImageSrc"
            class="img_bitton_open"
          />
        </div>
      </button>
    </div>
    <div class="container_filter" :class="{ active: isFilterOpen === 1 }">
      <div class="box_filter">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          class="filter_button"
          :class="{ active: activeButtons.includes(index) }"
          @click="handleClick(index)"
        >
          {{ button.text }}
        </button>
      </div>
    </div>

    <div class="box_button_blog">
      <button class="button_blog">Все статьи</button>
      <button class="button_blog">Разработка</button>
      <button class="button_blog">Дизайн</button>
      <button class="button_blog">Маркетинг</button>
      <button class="button_blog">Автоматизация</button>
    </div>
    <div class="container_search_blog">
      <div @click="toggleModal" ref="box_search_blog" class="box_search_blog">
        <img class="union_search" src="@/assets/elements/Union.svg" />
      </div>
      <div ref="box_search_blog_full" class="box_search_blog_full">
        <div class="box_left_search">
          <img class="union_search" src="@/assets/elements/Union.svg" />
          <input class="input_search" placeholder="Поиск" />
        </div>
        <img
          @click="closeModal"
          class="cross_search"
          src="@/assets/elements/cross.svg"
        />
      </div>
    </div>
  </section> -->
  <section class="section_blog_articles">
    <router-link :to="articlesData[0].path" class="box_big_articles">
      <img :src="articlesData[0].image" class="big_img_articles" />
      <p class="font_1rem_400_Raleway color_violet">
        {{ articlesData[0].date }}
      </p>
      <p class="title_services_design">
        {{ articlesData[0].title }}
      </p>
    </router-link>
    <div class="container_right_newsletter">
      <div class="box_right_newsletter">
        <!-- <div class="text_newsletter">
          <p class="title_services_design">Подпишись на нашу рассылку</p>
          <p class="font_1rem_400_Raleway color_violet">
            Мы отправляем полезные<br />
            материалы, которые помогут<br />
            вам в работе
          </p>
        </div>
        <div class="box_input_newsletter">
          <input v-model="email" class="input_newsletter" placeholder="Email" />
          <button class="button_newsletter" @click="handleSubmit">
            {{ isEmailValid ? "" : "ОК" }}
            <img
              v-if="isEmailValid"
              src="@/assets/elements/check_mark.svg"
              class="check_mark"
            />
          </button>
          <p v-if="showErrorMessage" class="error-message">
            Некорректный Email*
          </p>
        </div> -->
        <p class="title_services_design">Подпишись на наши соцсети</p>
        <p class="font_1_400_Kreadon_Regular color_violet">
          Мы отправляем полезные материалы, которые помогут вам в работе. Мы
          отправляем полезные материалы, которые помогут вам в работе
        </p>
        <div class="container_button_networks_articles">
          <div class="box_button_networks_articles">
            <button class="button_v1">Telegram</button>
          </div>
          <div class="box_button_networks_articles">
            <button class="button_v1">Youtube</button>
          </div>
        </div>
      </div>
      <router-link :to="articlesData[1].path" class="box_articles">
        <img :src="articlesData[1].image" class="img_articles" />
        <p class="font_1rem_400_Raleway color_violet">
          {{ articlesData[1].date }}
        </p>
        <p class="input_search">
          {{ articlesData[1].title }}
        </p>
      </router-link>
    </div>
    <router-link
      v-for="(article, index) in articlesData.slice(2)"
      :key="index"
      class="box_articles full_version"
      :to="article.path"
    >
      <img :src="article.image" class="img_articles" />
      <p class="font_1rem_400_Raleway color_violet">
        {{ article.date }}
      </p>
      <p class="input_search">
        {{ article.title }}
      </p>
    </router-link>
    <!-- <div class="box_right_newsletter mobail_version">
      <div class="text_newsletter">
        <p class="font_2_18_mobil">Подпишись на нашу рассылку</p>
        <p class="font_1rem_400_Raleway color_violet">
          Мы отправляем полезные<br />
          материалы, которые помогут<br />
          вам в работе
        </p>
      </div>
      <div class="box_input_newsletter">
        <input v-model="email" class="input_newsletter" placeholder="Email" />
        <button class="button_newsletter" @click="handleSubmit">
          {{ isEmailValid ? "" : "ОК" }}
          <img
            v-if="isEmailValid"
            src="@/assets/elements/check_mark.svg"
            class="check_mark"
          />
        </button>
        <p v-if="showErrorMessage" class="error-message">Некорректный Email*</p>
      </div>
    </div> -->
    <div class="box_right_newsletter mobail_version">
      <!-- <div class="text_newsletter">
          <p class="title_services_design">Подпишись на нашу рассылку</p>
          <p class="font_1rem_400_Raleway color_violet">
            Мы отправляем полезные<br />
            материалы, которые помогут<br />
            вам в работе
          </p>
        </div>
        <div class="box_input_newsletter">
          <input v-model="email" class="input_newsletter" placeholder="Email" />
          <button class="button_newsletter" @click="handleSubmit">
            {{ isEmailValid ? "" : "ОК" }}
            <img
              v-if="isEmailValid"
              src="@/assets/elements/check_mark.svg"
              class="check_mark"
            />
          </button>
          <p v-if="showErrorMessage" class="error-message">
            Некорректный Email*
          </p>
        </div> -->
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
    <router-link
      v-for="(article, index) in articlesData.slice(0)"
      :key="index"
      class="box_articles mobail_version"
      :to="article.path"
    >
      <img :src="article.image" class="img_articles" />
      <p class="font_1rem_400_Raleway color_violet">
        {{ article.date }}
      </p>
      <p class="input_search">
        {{ article.title }}
      </p>
    </router-link>
    <!-- <div class="box_margin_button_v3">
      <div class="box_button_v3">
        <button class="button_v1">БОЛЬШЕ СТАТЕЙ</button>
      </div>
    </div> -->
  </section>
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import { ref } from "vue";
import { useHead } from "@vueuse/head";
import finalization from "@/assets/article_illustrations/finalization.webp";
import one_pager_or from "@/assets/article_illustrations/one-pager_or.webp";
import turnkey from "@/assets/article_illustrations/turnkey.webp";
import corporate from "@/assets/article_illustrations/corporate.webp";
import development_stages from "@/assets/article_illustrations/development_stages.webp";

export default {
  components: { HeaderPages, FooterPages },
  setup() {
    const email = ref("");
    const isEmailValid = ref(false);
    const showErrorMessage = ref(false);

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isEmailValid.value = emailRegex.test(email.value);
    };
    const articlesData = ref([
      {
        date: "24 ноября 2024",
        title: "Важные шаги в доработке уже существующего сайта",
        image: finalization,
        path: "/Important_steps_in_refining_an_existing_website",
      },
      {
        date: "22 ноября 2024",
        title:
          "Многостраничный сайт или одностраничный: что лучше для вашего бизнеса?",
        image: one_pager_or,
        path: "/Multi_page_site_or_one_page_site",
      },
      {
        date: "18 ноября 2024",
        title: "Как интернет-магазин под ключ увеличит ваши продажи",
        image: turnkey,
        path: "/How_a_turnkey_online_store_will_increase_your_sales",
      },
      {
        date: "15 ноября 2024",
        title: "Как разработать корпоративный сайт, который работает на бизнес",
        image: corporate,
        path: "/How_to_develop_a_corporate_website_that_works_for_business",
      },
      {
        date: "12 ноября 2024",
        title: "Этапы разработки сайта: полное руководство",
        image: development_stages,
        path: "/Stages_of_website_development_complete_guide_from_idea_to_launch",
      },
    ]);
    useHead({
      title: "Блог - MTI-Dev",
      meta: [
        {
          name: "description",
          content:
            "Интересные статьи о разработке сайтов, UX/UI дизайне, SEO-продвижении и цифровых сервисах. Полезные советы, кейсы и тренды для успешного развития вашего бизнеса.",
        },
      ],
    });
    const handleSubmit = () => {
      validateEmail(); // Проверяем email перед отправкой
      if (isEmailValid.value) {
        console.log("Email:", email.value); // Отправляем email в консоль
        showErrorMessage.value = false; // Скрываем сообщение об ошибке
      } else {
        showErrorMessage.value = true; // Покажем сообщение об ошибке
      }
    };

    return {
      email,
      isEmailValid,
      showErrorMessage,
      validateEmail,
      handleSubmit,
      articlesData,
    };
  },
  methods: {
    toggleModal() {
      this.$refs.box_search_blog_full.classList.toggle("active");
      this.$refs.box_search_blog.classList.toggle("active");
    },
    handleClick(index) {
      if (this.activeButtons.includes(index)) {
        // Если кнопка уже активна, удаляем ее из массива
        this.activeButtons = this.activeButtons.filter((i) => i !== index);
      } else {
        // Иначе добавляем индекс в массив активных кнопок
        this.activeButtons.push(index);
      }
      console.log("Индекс:", index);
      console.log("Текст:", this.buttons[index].text);
    },
    closeModal() {
      this.$refs.box_search_blog_full.classList.toggle("active");
      this.$refs.box_search_blog.classList.toggle("active");
    },
    toggleFilter(filterId) {
      if (this.isFilterOpen === filterId) {
        this.isFilterOpen = null; // Закрываем фильтр, если он уже открыт
      } else {
        this.isFilterOpen = filterId; // Открываем выбранный фильтр
      }
    },
  },
  data() {
    return {
      activeButtons: [],
      buttons: [
        { text: "Все статьи" },
        { text: "Разработка" },
        { text: "Дизайн" },
        { text: "Дизайн" },
      ],
      openImageSrc: require("@/assets/elements/img_bitton_open.svg"),
      closeImageSrc: require("@/assets/elements/img_bitton_close.svg"),
      isFilterOpen: null,
    };
  },
};
</script>
<style scoped>
.box_right_newsletter {
  gap: 0.6rem;
}
.container_right_newsletter {
  gap: 3rem;
}
</style>
