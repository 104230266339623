<template>
  <HeaderPages />
  <section class="section_article">
    <div class="container_article_left">
      <div class="main_block_article">
        <p class="font_1rem_400_Raleway color_violet">24 ноября 2024</p>
        <h2 class="title_services_design">
          Важные шаги в доработке уже существующего сайта
        </h2>
        <img
          class="illustrations_articles"
          src="@/assets/article_illustrations/finalization.webp"
        />
        <div class="autor_mobile">
          <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
          <!-- <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                Frontend-разработчик
              </p>
            </div>
          </div> -->
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
        <h1 class="font_1_6_500_Raleway">
          Как доработка сайта помогает вашему бизнесу
        </h1>
        <p class="font_1_2_500_Raleway">
          Ваш сайт уже создан, но его работа далека от идеала? Он не приносит
          ожидаемых результатов, медленно загружается, или пользователи
          испытывают трудности с навигацией? Доработка сайта — это не только
          устранение ошибок, но и улучшение функционала, дизайна и оптимизация
          под современные требования. В этой статье мы разберем ключевые шаги
          доработки сайта, которые помогут вам сделать его эффективным
          инструментом для бизнеса.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">
        Когда и зачем требуется доработка сайта?
      </p>
      <h2 class="font_700 font_1rem_400_Raleway">
        Признаки, что сайт нуждается в доработке
      </h2>
      <div class="box_text_articles">
        <div>
          <p class="font_1rem_400_Raleway font_500">
            1.Медленная загрузка страниц.
          </p>
          <p class="font_0_9_400_Raleway">
            Пользователи уходят, не дождавшись загрузки сайта.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            2.Визуальный стиль не соответствует современным трендам.
          </p>
          <p class="font_0_9_400_Raleway">
            Визуальный стиль не соответствует современным трендам.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">3.Низкая конверсия.</p>
          <p class="font_0_9_400_Raleway">
            Посетители заходят на сайт, но не совершают целевые действия.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">4.Ошибки в работе.</p>
          <p class="font_0_9_400_Raleway">
            Не работают формы обратной связи, кнопки или другие элементы.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            5.Отсутствие адаптивности.
          </p>
          <p class="font_0_9_400_Raleway">
            Сайт неудобен для просмотра на мобильных устройствах.
          </p>
          <br />
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">Цели доработки сайта</h2>
        <p class="font_1rem_400_Raleway font_500">
          Основная цель доработки — сделать сайт удобным, быстрым и эффективным:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>Увеличить количество пользователей, остающихся на сайте.</li>
          <li>Упростить процесс взаимодействия с ресурсом.</li>
          <li>
            Повысить позиции в поисковых системах за счет технической и
            SEO-оптимизации.
          </li>
          <li>
            Улучшить визуальную привлекательность и пользовательский опыт.
          </li>
        </ul>
      </div>

      <p class="font_1_6_500_Raleway">Этапы доработки существующего сайта</p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          1.Анализ текущего состояния сайта
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Прежде чем приступить к доработке, необходимо провести детальный аудит
          сайта:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway font_500">Технический аудит.</p>
            <p class="font_0_9_400_Raleway">
              Оценка удобства интерфейса для пользователей.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway font_500">UX-аудит.</p>
            <p class="font_0_9_400_Raleway">
              Проверка скорости , исправности всех элементов, соответствия
              стандартам безопасности.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway font_500">SEO-аудит.</p>
            <p class="font_0_9_400_Raleway">
              Анализ позиций сайта в поисковых системах, качества контента и
              мета-тегов.
            </p>
          </li>
        </ul>
        <p class="font_0_9_400_Raleway font_500">
          Результаты анализа помогут определить слабые места сайта и составить
          план работ.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.Улучшение дизайна и интерфейса
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Дизайн играет ключевую роль в восприятии сайта пользователями. На этом
          этапе:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Разрабатываются макеты с современным дизайном, соответствующим
              бренду.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Добавляются адаптивные элементы, чтобы сайт хорошо отображался на
              любых устройствах.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Упрощается навигация, чтобы пользователи могли легко находить
              нужную информацию.
            </p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Оптимизация скорости загрузки
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Скорость загрузки — один из важнейших факторов успеха сайта:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Оптимизация изображений и других медиафайлов.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Настройка кэширования страниц.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Уменьшение количества HTTP-запросов.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Использование современных технологий, таких как CDN (Content
              Delivery Network).
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">
          4.Обновление и добавление контента
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Качественный контент привлекает посетителей и удерживает их внимание:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Обновление устаревших текстов и добавление новой информации.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Оптимизация контента под SEO: включение ключевых слов, создание
              мета-тегов.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Размещение блогов, кейсов или новостей, чтобы показать экспертизу
              компании.
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">
          5.Интеграция с современными инструментами
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Современные сайты должны быть функциональными и удобными:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Интеграция с CRM для автоматизации работы с клиентами.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Добавление чат-ботов и онлайн-консультантов для быстрого ответа на
              запросы.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Установка платёжных систем для онлайн-оплаты, если сайт
              предполагает продажи.
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">
          6.SEO-оптимизация и продвижение
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          После технической доработки и обновления контента проводится
          SEO-оптимизация:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Улучшение внутренней структуры сайта: перелинковка, настройка URL.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Оптимизация страниц под ключевые запросы.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Работа с внешними ссылками для повышения авторитета сайта.
            </p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">
          Чего можно добиться после доработки сайта?
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Улучшение пользовательского опыта
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">Пользователи оценят:</p>
          <ul class="list_articles font_0_9_400_Raleway">
            <li>
              <p class="font_0_9_400_Raleway">Быструю загрузку страниц.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Удобный интерфейс.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Понятную навигацию.</p>
            </li>
          </ul>
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">Рост конверсии</h2>
        <p class="font_0_9_400_Raleway">
          Благодаря новым функциональным элементам и оптимизированному контенту
          больше пользователей будут совершать целевые действия.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Повышение позиций в поисковиках
        </h2>
        <p class="font_0_9_400_Raleway">
          SEO-оптимизация поможет сайту занять более высокие позиции в
          результатах поиска, что увеличит органический трафик.
        </p>
      </div>
      <div class="box_wave_articles">
        <p class="font_1_2_500_Raleway">
          Доработка сайта как шаг к успеху бизнеса
        </p>
        <p class="font_0_9_400_Raleway">
          Доработка существующего сайта — это возможность вдохнуть новую жизнь в
          ваш ресурс, сделать его современным, удобным и эффективным. Если ваш
          сайт не приносит ожидаемых результатов, пора задуматься о его
          улучшении. Начните с анализа текущего состояния, составьте план работ
          и доверьте реализацию профессионалам, чтобы получить ресурс, который
          будет работать на ваш бизнес.
        </p>
        <img
          class="wave_small_articles"
          src="@/assets/background/wave_small_articles.svg"
        />
      </div>
    </div>
    <div class="container_article_right">
      <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
      <div class="box_autor_article">
        <div class="autor_article_first">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div>
        <!-- <div class="autor_article_first m_autor_article">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div> -->
        <div ref="box_all_autor_article" class="box_all_autor_article">
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_article_bottom">
    <div class="container_article_bottom">
      <div class="box_back_article">
        <button class="button_back_article">
          <img
            src="@/assets/elements/arrow_left.svg"
            class="arrow_left_article"
          />
        </button>
        <p class="text_small_kreadon-bold color_violet">
          вернуться к списку статей
        </p>
      </div>
    </div>
  </section>
  <FooterArticles />
</template>
<script>
import { useHead } from "@vueuse/head";
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterArticles from "@/components/permanent/FooterArticles.vue";

export default {
  components: { HeaderPages, FooterArticles },
  data() {
    return {
      isModalOpen: false, // Состояние модального окна
      openImageSrc: require("@/assets/elements/arrow_bottom_white.svg"),
      closeImageSrc: require("@/assets/elements/arrow_top_white.svg"),
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen; // Переключение состояния
      this.$refs.box_all_autor_article.classList.toggle("active");
    },
    closeModal() {
      this.isModalOpen = false; // Сброс состояния
      this.$refs.box_all_autor_article.classList.remove("active");
    },
  },
  setup() {
    useHead({
      title: "Важные шаги доработки сайта: как улучшить существующий ресурс",
      meta: [
        {
          name: "description",
          content:
            "Узнайте, какие шаги необходимо предпринять для доработки уже существующего сайта. Улучшение дизайна, оптимизация скорости, SEO и другие ключевые этапы, чтобы сделать ваш сайт более эффективным.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.font_500 {
  font-weight: 500 !important;
}
</style>
