<template>
  <HeaderPages />
  <section class="section_article">
    <div class="container_article_left">
      <div class="main_block_article">
        <p class="font_1rem_400_Raleway color_violet">15 ноября 2024</p>
        <h2 class="title_services_design">
          Как разработать корпоративный сайт, который работает на бизнес
        </h2>
        <img
          class="illustrations_articles"
          src="@/assets/article_illustrations/corporate.webp"
        />
        <div class="autor_mobile">
          <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
          <!-- <div class="all_autor_article">
              <img
                class="img_autor_article_small"
                src="@/assets/elements/img_autor_article.svg"
              />
              <div class="box_text_all_autor_article">
                <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
                <p class="font_1_400_Kreadon_Regular color_violet">
                  Frontend-разработчик
                </p>
              </div>
            </div> -->
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
        <h1 class="font_1_6_500_Raleway">
          Корпоративный сайт: ключ к успеху вашего бизнеса
        </h1>
        <p class="font_1_2_500_Raleway">
          Современный корпоративный сайт — это не просто визитная карточка
          компании, а мощный инструмент для привлечения клиентов, построения
          имиджа и оптимизации бизнес-процессов. Но чтобы сайт действительно
          работал на бизнес, его разработка должна учитывать потребности вашей
          аудитории и цели компании. В этой статье мы расскажем, как создать
          эффективный корпоративный сайт, который станет вашим главным
          помощником.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">
        Зачем бизнесу нужен корпоративный сайт?
      </p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          Важность корпоративного сайта
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Корпоративный сайт выполняет сразу несколько функций:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>Представляет компанию в интернете.</li>
          <li>
            Помогает клиентам найти информацию о ваших услугах и продуктах.
          </li>
          <li>
            Упрощает коммуникацию с клиентами через формы обратной связи и чаты.
          </li>
          <li>Поддерживает ваш имидж как современного и надёжного партнёра.</li>
        </ul>
      </div>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">Преимущества для бизнеса</h2>

        <p class="font_1rem_400_Raleway font_500">
          Эффективный корпоративный сайт даёт вашему бизнесу:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_1rem_400_Raleway font_500">
              Повышение узнаваемости бренда.
            </p>
            <p class="font_0_9_400_Raleway">
              Информативный и визуально привлекательный сайт выделяет вас среди
              конкурентов.
            </p>
          </li>
          <li>
            <p class="font_1rem_400_Raleway font_500">Рост продаж.</p>
            <p class="font_0_9_400_Raleway">
              Сайт может содержать интернет-магазин или лендинг для продвижения
              конкретных услуг.
            </p>
          </li>
          <li>
            <p class="font_1rem_400_Raleway font_500">Оптимизация процессов.</p>
            <p class="font_0_9_400_Raleway">
              Интеграция с CRM и автоматизация задач ускоряют внутренние
              процессы.
            </p>
          </li>
        </ul>
      </div>

      <p class="font_1_6_500_Raleway">
        Основные этапы разработки корпоративного сайта
      </p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          1.Постановка целей и задач
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Перед разработкой сайта важно определить его цели:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Увеличение продаж.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Привлечение клиентов.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Информирование об услугах.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Построение бренда.</p>
          </li>
        </ul>
        <p class="font_0_9_400_Raleway font_500">
          На основе этих целей формируется концепция сайта, включая его
          структуру и функционал.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.Исследование целевой аудитории
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Успех сайта зависит от его соответствия потребностям вашей аудитории.
          Для этого проводится:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Анализ интересов и предпочтений пользователей.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Изучение конкурентов.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Разработка пользовательских сценариев (UX).
            </p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Создание технического задания
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Техническое задание (ТЗ) включает:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Подробное описание структуры сайта.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Требования к дизайну и функционалу.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Необходимые интеграции (CRM, платёжные системы и т. д.).
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Сроки реализации проекта.</p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">4.Разработка дизайна</h2>
        <p class="font_1rem_400_Raleway font_500">
          Дизайн корпоративного сайта должен быть:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Уникальным и соответствующим вашему бренду.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Удобным и адаптивным для любых устройств.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Визуально привлекательным, чтобы удерживать внимание посетителей.
              На этом этапе создаются макеты страниц, которые утверждаются
              заказчиком.
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">
          5.Программирование и интеграция
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          После утверждения дизайна сайт переходит в стадию разработки:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Вёрстка страниц.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Настройка функционала (формы обратной связи, калькуляторы,
              чат-боты).
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Интеграция с CRM, ERP или другими системами.
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">6.Наполнение контентом</h2>
        <p class="font_1rem_400_Raleway font_500">
          Контент на сайте должен быть:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Уникальным и информативным.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Оптимизированным для SEO.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Соответствующим запросам целевой аудитории. <br />
              Это могут быть статьи, описания услуг, портфолио, отзывы клиентов.
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">7.Тестирование и запуск</h2>
        <p class="font_1rem_400_Raleway font_500">Перед запуском проводится:</p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Проверка сайта на работоспособность.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Оптимизация скорости загрузки.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Кроссбраузерное и мобильное тестирование. <br />
              Только после этого сайт становится доступен для пользователей.
            </p>
          </li>
        </ul>
      </div>
      <p class="font_1_6_500_Raleway">
        Ключевые элементы успешного корпоративного сайта
      </p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">1.Интуитивная навигация</h2>
        <p class="font_1rem_400_Raleway font_500">
          Удобная структура сайта позволяет пользователям легко находить нужную
          информацию. Для этого важно:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Минимизировать количество кликов до цели.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Использовать понятные названия разделов.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Включить хлебные крошки и поисковую строку.
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">2.Адаптивный дизайн</h2>
        <p class="font_1rem_400_Raleway font_500">
          Сайт должен корректно отображаться на всех устройствах: от ПК до
          смартфонов. Адаптивность улучшает пользовательский опыт и влияет на
          SEO.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Оптимизация под поисковые системы
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          SEO-продвижение начинается с разработки:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Создание уникальных мета-тегов.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Включение ключевых слов в тексты.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Использование быстрых и безопасных хостингов.
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          4.Интеграция с системами управления
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Интеграция с CRM, ERP или платёжными системами автоматизирует многие
          процессы:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Ведение клиентской базы.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Ускорение обработки заявок.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Повышение качества обслуживания.</p>
          </li>
        </ul>
      </div>

      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">
          Советы по разработке корпоративного сайта
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          1.Учитывайте особенности вашего бизнеса
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">
            Каждый корпоративный сайт уникален. Учитывайте:
          </p>
          <ul class="list_articles font_0_9_400_Raleway">
            <li>
              <p class="font_0_9_400_Raleway">
                Сложность предоставляемых услуг.
              </p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Географию бизнеса.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Потребности вашей аудитории.</p>
            </li>
          </ul>
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.Не экономьте на дизайне
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">
            Профессиональный дизайн помогает:
          </p>
          <ul class="list_articles font_0_9_400_Raleway">
            <li>
              <p class="font_0_9_400_Raleway">Привлечь внимание посетителей.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Выделиться среди конкурентов.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">
                Повысить доверие к вашему бренду.
              </p>
            </li>
          </ul>
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Работайте с профессионалами
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">
            Обратитесь к опытной команде, которая обеспечит:
          </p>
          <ul class="list_articles font_0_9_400_Raleway">
            <li>
              <p class="font_0_9_400_Raleway">Высокое качество разработки.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Соблюдение сроков.</p>
            </li>
            <li>
              <p class="font_0_9_400_Raleway">Поддержку после запуска.</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="box_wave_articles">
        <p class="font_1_2_500_Raleway">
          Корпоративный сайт как инструмент роста бизнеса
        </p>
        <p class="font_0_9_400_Raleway">
          Разработка корпоративного сайта — это инвестиция, которая окупается
          благодаря росту продаж, упрощению бизнес-процессов и укреплению имиджа
          компании. Создавая сайт, ориентированный на потребности вашей
          аудитории и цели бизнеса, вы получаете мощный инструмент, который
          работает на вас.
        </p>
        <img
          class="wave_small_articles"
          src="@/assets/background/wave_small_articles.svg"
        />
      </div>
    </div>
    <div class="container_article_right">
      <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
      <div class="box_autor_article">
        <div class="autor_article_first">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div>
        <!-- <div class="autor_article_first m_autor_article">
            <img
              class="img_autor_article"
              src="@/assets/elements/img_autor_article.svg"
            />
            <button @click="toggleModal" class="button_open_autor_article">
              <img
                :src="isModalOpen ? closeImageSrc : openImageSrc"
                class="img_button_open_autor_article"
              />
            </button>
          </div> -->
        <div ref="box_all_autor_article" class="box_all_autor_article">
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_article_bottom">
    <div class="container_article_bottom">
      <div class="box_back_article">
        <button class="button_back_article">
          <img
            src="@/assets/elements/arrow_left.svg"
            class="arrow_left_article"
          />
        </button>
        <p class="text_small_kreadon-bold color_violet">
          вернуться к списку статей
        </p>
      </div>
    </div>
  </section>
  <FooterArticles />
</template>
<script>
import { useHead } from "@vueuse/head";
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterArticles from "@/components/permanent/FooterArticles.vue";

export default {
  components: { HeaderPages, FooterArticles },
  data() {
    return {
      isModalOpen: false, // Состояние модального окна
      openImageSrc: require("@/assets/elements/arrow_bottom_white.svg"),
      closeImageSrc: require("@/assets/elements/arrow_top_white.svg"),
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen; // Переключение состояния
      this.$refs.box_all_autor_article.classList.toggle("active");
    },
    closeModal() {
      this.isModalOpen = false; // Сброс состояния
      this.$refs.box_all_autor_article.classList.remove("active");
    },
  },
  setup() {
    useHead({
      title:
        "Корпоративный сайт для бизнеса: как разработать эффективный инструмент",
      meta: [
        {
          name: "description",
          content:
            "Узнайте, как разработать корпоративный сайт, который будет работать на ваш бизнес. Этапы создания, ключевые элементы и советы по повышению эффективности.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.font_500 {
  font-weight: 500 !important;
}
</style>
