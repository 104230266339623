<template>
  <section class="container_briefing">
    <img class="img_briefing" src="@/assets/elements/briefing.png" />
    <div class="box_text_briefing">
      <p class="big_text color_white text_align_right">
        СВЯЗАТЬСЯ<br />
        С НАМИ
      </p>
      <div class="container_briefing_form_main">
        <div class="container_briefing_form" :class="{ sent: isSent }">
          <input
            type="text"
            placeholder="Имя"
            class="input_briefing_footer"
            :class="{ error_input: errors.name }"
            v-model="formData.name"
            @input="onInput('name')"
            @blur="validateName"
            @keypress="filterName"
          />
          <div class="box_error_name">
            <span v-if="errors.name" class="text_error">{{ errors.name }}</span>
          </div>
          <input
            type="text"
            placeholder="Номер"
            class="input_briefing_footer"
            :class="{ error_input: errors.phone }"
            v-model="formData.phone"
            @input="onInput('phone')"
            @blur="validatePhone"
            @keypress="filterPhone"
          />
          <div class="box_error_phone">
            <span v-if="errors.phone" class="text_error">{{
              errors.phone
            }}</span>
          </div>
          <div class="box_button_v2">
            <button @click="submitForm" class="button_v1">
              Оставить заявку
            </button>
          </div>
          <div class="box_confirm_bid">
            <input
              type="checkbox"
              class="input_checkbox"
              :class="{
                active: isCheckboxActive,
                error_checkbox: !isCheckboxActive && triedToSubmit,
              }"
              @change="toggleCheckbox"
            />
            <p class="subtext_services_design color_violet">
              Согласен на обработку персональных данных Ознакомлен с
              <a class="underline color_violet" href="https://mti-dev.ru/privacy_policy.pdf" target="_blank"
                >политикой конфиденциальности</a
              >
            </p>
          </div>
        </div>
        <div class="container_briefing_form_sent" :class="{ sent: isSent }">
          <p class="text_small_kreadon-bold color_white text_align_center">
            Мы получили вашу заявку.<br />
            В ближайшее время мы свяжемся с вами
          </p>
          <div class="box_button_v2">
            <button class="button_v1">
              Заявка отправлена
              <img src="@/assets/elements/check_mark.svg" class="check_mark" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        phone: "",
      },
      errors: {
        name: null,
        phone: null,
      },
      touched: {
        name: false,
        phone: false,
      },
      isCheckboxActive: false,
      triedToSubmit: false, // Флаг для отображения ошибки у чекбокса
      isSent: false,
      isActive: false,
    };
  },
  methods: {
    onInput(field) {
      this.touched[field] = true; // Помечаем поле как "использованное"
      this.errors[field] = null; // Помечаем поле как "было использовано"
    },
    filterName(event) {
      const char = String.fromCharCode(event.keyCode || event.which);
      const allowedRegex = /^[a-zA-Zа-яА-Я\s]*$/;
      if (!char.match(allowedRegex)) {
        event.preventDefault(); // Запрещаем ввод символа
      }
    },
    validateName() {
      const nameRegex = /^[a-zA-Zа-яА-Я\s]*$/;
      if (!this.formData.name) {
        this.errors.name = "Обязательное поле";
      } else if (this.formData.name.length < 2) {
        this.errors.name = "Минимум 2 символа.";
      } else if (!this.formData.name.match(nameRegex)) {
        this.errors.name = "Имя может содержать только буквы.";
      } else {
        this.errors.name = null;
      }
    },
    filterPhone(event) {
      const char = String.fromCharCode(event.keyCode || event.which);
      const allowedRegex = /^[\d+]*$/; // Разрешаем только цифры и знак "+"
      if (!char.match(allowedRegex)) {
        event.preventDefault(); // Запрещаем ввод символа
      }
    },
    validatePhone() {
      const phoneRegex = /^(\+7|8|7)\d{10}$/;
      if (!this.formData.phone) {
        this.errors.phone = "Обязательное поле";
      } else if (!this.formData.phone.match(phoneRegex)) {
        this.errors.phone = "Введите корректный номер";
      } else {
        this.errors.phone = null;
      }
    },
    toggleCheckbox() {
      this.isCheckboxActive = !this.isCheckboxActive;
    },
    submitForm() {
      this.triedToSubmit = true;
      this.validateName();
      this.validatePhone();

      if (!this.errors.name && !this.errors.phone && this.isCheckboxActive) {
        // console.log({
        //   name: this.formData.name,
        //   phone: this.formData.phone,
        // });
        this.sendFooterOrder();
        this.triedToSubmit = false;
        this.isSent = true;
        // alert("Форма успешно отправлена!");
      }
      //   else {
      //     alert("Пожалуйста, заполните все поля и подтвердите согласие.");
      //   }
    },
    sendFooterOrder() {
      return fetch("https://mti-dev.ru:49152/footerFeedBack", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({
          name: this.formData.name,
          phone: this.formData.phone,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          return response.data;
        });
    },
  },
};
</script>
<style scoped>
.container_briefing_form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 1;
  transition: 0.5s ease;
}
.container_briefing_form_main {
  width: 23.6rem;
  height: 16.5rem;
  position: relative;
}
.container_briefing_form_sent {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  opacity: 0;
  z-index: -2;
  transition: 0.5s ease;
}
.container_briefing_form_sent.sent {
  opacity: 1;
  z-index: 1;
  transition: 0.5s ease;
}
.box_error_name {
  position: absolute;
  top: 3.3rem;
  left: 3rem;
}
.box_confirm_bid {
  gap: 0.8rem;
}
.box_error_phone {
  position: absolute;
  top: 7.9rem;
  left: 3rem;
}
.input_checkbox {
  background: rgb(26, 27, 31);
  transition: 0.5s ease;
}
.check_mark {
  width: 1.1rem;
  margin-left: 1rem;
}
.error_input {
  border: 0.1rem solid #ce2f57 !important;
}
.input_checkbox.active {
  background: rgb(91, 108, 255);
  transition: 0.5s ease;
  background-image: url("@/assets/elements/check_mark.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-size: 1rem 1rem;
}
.input_briefing_footer {
  border: 0.08rem solid rgb(91, 108, 255, 0);
  width: 100%;
  height: 3.4rem;
  border-radius: 1.78rem;
  background: rgb(34, 36, 47);
  padding: 1.1rem 3rem;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-family: Kreadon-Regular;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
}
.box_button_v2 {
  width: 100%;
}
.container_briefing_form.sent {
  opacity: 0;
  transition: 0.5s ease;
}
.input_briefing_footer:hover {
  border: 0.08rem solid rgb(91, 108, 255);
  background: rgb(41, 47, 70);
  transition: 0.5s ease;
}
@media (max-width: 767px) {
  .box_text_briefing {
    width: 100%;
    gap: 1rem;
  }
  .container_briefing_form_main {
    width: 100%;
    height: fit-content;
  }
  .container_briefing_form {
    gap: 2rem;
  }
  .input_briefing_footer {
    height: 4.55rem;
    border-radius: 3.23rem;
    padding: 1.5rem 2.8rem;
    font-size: 1.27rem;
    font-weight: 400;
    line-height: 1.64rem;
  }
  .box_error_name {
    top: 4.7rem;
  }
  .box_error_phone {
    top: 11.3rem;
  }
}
</style>
