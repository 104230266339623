<template>
  <HeaderPages />
  <section class="section_article">
    <div class="container_article_left">
      <div class="main_block_article">
        <p class="font_1rem_400_Raleway color_violet">22 ноября 2024</p>
        <h2 class="title_services_design">
          Многостраничный сайт или одностраничный: что лучше для вашего бизнеса?
        </h2>
        <img
          class="illustrations_articles"
          src="@/assets/article_illustrations/one-pager_or.webp"
        />
        <div class="autor_mobile">
          <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
          <!-- <div class="all_autor_article">
              <img
                class="img_autor_article_small"
                src="@/assets/elements/img_autor_article.svg"
              />
              <div class="box_text_all_autor_article">
                <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
                <p class="font_1_400_Kreadon_Regular color_violet">
                  Frontend-разработчик
                </p>
              </div>
            </div> -->
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>

        <h1 class="font_1_6_500_Raleway">
          Многостраничный или одностраничный сайт: как сделать правильный выбор
        </h1>
        <p class="font_1_2_500_Raleway">
          Каждый сайт уникален, и выбор его формата зависит от целей и задач
          вашего бизнеса. Многостраничные сайты удобны для масштабных проектов,
          а одностраничные сайты — идеальны для фокусировки внимания на одном
          предложении. В этой статье мы рассмотрим, какой тип сайта лучше
          подойдёт для вашего бизнеса, разберём их особенности и поможем принять
          взвешенное решение.
        </p>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">
          Что такое многостраничный сайт и для чего он нужен?
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Многостраничный сайт: структура и функционал
        </h2>
        <p class="font_0_9_400_Raleway font_500">
          Многостраничный сайт — это комплексный веб-ресурс, который состоит из
          нескольких разделов и страниц. Такой формат подходит для бизнеса,
          который предлагает широкий спектр товаров или услуг. Например,
          корпоративный портал, интернет-магазин или образовательная платформа.
        </p>
      </div>
      <h2 class="font_700 font_1rem_400_Raleway">
        Преимущества многостраничного сайта
      </h2>
      <div class="box_text_articles">
        <div>
          <p class="font_1rem_400_Raleway font_500">
            1.Детализация информации.
          </p>
          <p class="font_0_9_400_Raleway">
            Каждая страница отвечает за конкретный раздел:<br />описание
            продуктов, услуги, отзывы или новости.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            2.Широкий потенциал для SEO.
          </p>
          <p class="font_0_9_400_Raleway">
            Каждая страница может быть оптимизирована под отдельные запросы,<br />
            что увеличивает охват аудитории.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            3.Подходит для сложных решений.
          </p>
          <p class="font_0_9_400_Raleway">
            Многостраничные сайты позволяют реализовать личные кабинеты,<br />
            калькуляторы, базы данных и другие инструменты.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            4.Удобство для крупных проектов.
          </p>
          <p class="font_0_9_400_Raleway">
            Компании с широким ассортиментом товаров или услуг получают<br />
            возможность структурировать всю информацию.
          </p>
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">
          Недостатки многостраничного сайта
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">
            1.Высокая стоимость разработки.
          </p>
          <p class="font_0_9_400_Raleway">
            Создание многостраничного сайта требует значительных затрат времени
            и ресурсов.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            2.Сложность в управлении.
          </p>
          <p class="font_0_9_400_Raleway">
            Такой сайт нуждается в регулярном обновлении контента и технической
            поддержке.
          </p>
        </div>
      </div>

      <p class="font_1_6_500_Raleway">
        Что такое одностраничный сайт и для чего он нужен?
      </p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          Одностраничный сайт: минимализм и функциональность
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Одностраничный сайт (лендинг) — это компактный ресурс, на котором вся
          информация представлена на одной странице. Пользователь прокручивает
          страницу вниз, чтобы получить полное представление о продукте или
          услуге.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Преимущества одностраничного сайта
        </h2>
        <div class="box_text_articles">
          <div>
            <p class="font_1rem_400_Raleway font_500">
              1.Простота и скорость восприятия.
            </p>
            <p class="font_0_9_400_Raleway">
              Весь контент сосредоточен на одной странице, что удобно для
              пользователей.
            </p>
            <br />
            <p class="font_1rem_400_Raleway font_500">2.Высокая конверсия.</p>
            <p class="font_0_9_400_Raleway">
              Одностраничные сайты оптимизированы для конкретного действия:<br />
              покупки, подписки, регистрации.
            </p>
            <br />
            <p class="font_1rem_400_Raleway font_500">
              3.Экономия времени и средств.
            </p>
            <p class="font_0_9_400_Raleway">
              Лендинги разрабатываются быстрее и требуют меньше вложений.
            </p>
            <br />
            <p class="font_1rem_400_Raleway font_500">4.Идеален для рекламы.</p>
            <p class="font_0_9_400_Raleway">
              Одностраничники часто используются в контекстной и таргетированной
              рекламе для быстрого продвижения.
            </p>
          </div>
          <div class="box_text_articles"></div>
          <h2 class="font_700 font_1rem_400_Raleway">
            Недостатки одностраничного сайта
          </h2>
          <div>
            <p class="font_1rem_400_Raleway font_500">
              1.Ограничение в контенте.
            </p>
            <p class="font_0_9_400_Raleway">
              Если нужно разместить много информации, лендинг может стать
              перегруженным.
            </p>
            <br />
            <p class="font_1rem_400_Raleway font_500">2.Сложности с SEO.</p>
            <p class="font_0_9_400_Raleway">
              Одностраничный сайт сложнее продвигать по широкому спектру
              запросов.
            </p>
            <br />
            <p class="font_1rem_400_Raleway font_500">
              3.Не подходит для масштабных проектов.
            </p>
            <p class="font_0_9_400_Raleway">
              Для интернет-магазинов или корпоративных порталов формат лендинга
              недостаточен.
            </p>
          </div>
        </div>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">
          Как выбрать формат сайта для вашего бизнеса?
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Оцените ваши цели и задачи
        </h2>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            Если вы хотите подробно рассказать о своей компании и предложениях,
            выбирайте многостраничный сайт.
          </li>
          <li>
            Если ваша задача — быстрое продвижение одного продукта или услуги,
            одностраничник станет идеальным решением.
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          Учитывайте особенности аудитории
        </h2>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            Многостраничный сайт лучше подходит для сложных проектов с разной
            целевой аудиторией.
          </li>
          <li>
            Одностраничный сайт привлекает внимание к конкретному предложению и
            удерживает пользователя.
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">Не забывайте о SEO</h2>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            Для продвижения по множеству ключевых запросов многостраничный сайт
            предоставляет больше возможностей.
          </li>
          <li>Одностраничник лучше подходит для узконаправленных запросов.</li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          Распределение бюджета и времени
        </h2>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            Если вы готовы инвестировать в масштабный проект, выбирайте
            многостраничный сайт.
          </li>
          <li>
            Если важна скорость разработки и экономия, рассмотрите лендинг.
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">Примеры использования форматов</p>
        <h2 class="font_700 font_1rem_400_Raleway">
          Когда выбрать многостраничный сайт
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">1.Корпоративные порталы.</p>
          <p class="font_0_9_400_Raleway">
            Компании с большим количеством услуг или сложной структурой бизнеса.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">2.Интернет-магазины.</p>
          <p class="font_0_9_400_Raleway">
            Магазины с каталогами продукции и интеграцией платёжных систем.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            3.Образовательные проекты.
          </p>
          <p class="font_0_9_400_Raleway">
            Платформы с курсами, тестами и личными кабинетами.
          </p>
        </div>
        <h2 class="font_700 font_1rem_400_Raleway">
          Когда выбрать одностраничный сайт
        </h2>
        <div>
          <p class="font_1rem_400_Raleway font_500">
            1.Запуск рекламных кампаний.
          </p>
          <p class="font_0_9_400_Raleway">
            Одностраничник помогает быстро донести информацию до целевой
            аудитории.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">2. Стартапы.</p>
          <p class="font_0_9_400_Raleway">
            Быстрое и бюджетное решение для запуска проекта.
          </p>
          <br />
          <p class="font_1rem_400_Raleway font_500">
            3. Услуги или конкретные продукты.
          </p>
          <p class="font_0_9_400_Raleway">
            Продвижение одной услуги, например, обучения, консалтинга или
            продажи конкретного товара.
          </p>
        </div>
      </div>
      <div class="box_wave_articles">
        <p class="font_1_2_500_Raleway">
          Выбор формата сайта — шаг к успеху вашего бизнеса
        </p>
        <p class="font_0_9_400_Raleway">
          Решение о выборе между многостраничным и одностраничным сайтом зависит
          от потребностей вашего бизнеса. Многостраничный сайт подходит для
          компаний с разнообразными услугами и продуктами, тогда как
          одностраничник лучше работает для продвижения конкретных предложений.
          В любом случае, профессиональная разработка сайта обеспечит его
          эффективность и достижение ваших целей.
        </p>
        <img
          class="wave_small_articles"
          src="@/assets/background/wave_small_articles.svg"
        />
      </div>
    </div>
    <div class="container_article_right">
      <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
      <div class="box_autor_article">
        <div class="autor_article_first">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div>
        <!-- <div class="autor_article_first m_autor_article">
            <img
              class="img_autor_article"
              src="@/assets/elements/img_autor_article.svg"
            />
            <button @click="toggleModal" class="button_open_autor_article">
              <img
                :src="isModalOpen ? closeImageSrc : openImageSrc"
                class="img_button_open_autor_article"
              />
            </button>
          </div> -->
        <div ref="box_all_autor_article" class="box_all_autor_article">
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_article_bottom">
    <div class="container_article_bottom">
      <div class="box_back_article">
        <button class="button_back_article">
          <img
            src="@/assets/elements/arrow_left.svg"
            class="arrow_left_article"
          />
        </button>
        <p class="text_small_kreadon-bold color_violet">
          вернуться к списку статей
        </p>
      </div>
    </div>
  </section>
  <FooterArticles />
</template>
<script>
import { useHead } from "@vueuse/head";
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterArticles from "@/components/permanent/FooterArticles.vue";

export default {
  components: { HeaderPages, FooterArticles },
  data() {
    return {
      isModalOpen: false, // Состояние модального окна
      openImageSrc: require("@/assets/elements/arrow_bottom_white.svg"),
      closeImageSrc: require("@/assets/elements/arrow_top_white.svg"),
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen; // Переключение состояния
      this.$refs.box_all_autor_article.classList.toggle("active");
    },
    closeModal() {
      this.isModalOpen = false; // Сброс состояния
      this.$refs.box_all_autor_article.classList.remove("active");
    },
  },
  setup() {
    useHead({
      title:
        "Что выбрать: многостраничный сайт или одностраничный? Советы для бизнеса",
      meta: [
        {
          name: "description",
          content:
            "Узнайте, что лучше подходит для вашего бизнеса — многостраничный сайт или одностраничный. Сравнение форматов, преимущества, недостатки и рекомендации.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.font_500 {
  font-weight: 500 !important;
}
</style>
