<template>
  <div class="margin_top"></div>
  <section class="section_briefing">
    <div class="wave_footer"></div>
    <BriefingSection />
  </section>
  <footer class="section_footer">
    <div class="container_footer">
      <div class="box_button_footer">
        <a href="https://mti-dev.ru/privacy_policy.pdf" target="_blank" class="subtext_services_design color_violet underline">
          Политика конфиденциальность
        </a>
        <!-- <p class="subtext_services_design color_violet">Какая-то кнопка</p> -->
      </div>
      <p class="subtext_services_design color_violet">© 2019-2024, MTI-Dev</p>
    </div>
  </footer>
</template>
<script>
import BriefingSection from "./BriefingSection.vue";
export default {
  components: {
    BriefingSection,
  },
};
</script>
