<template>
  <section class="section_projects">
    <div class="wave_down"></div>
    <div class="container_projects">
      <div class="box_title_services">
        <p class="title_services_design">ПРОЕКТЫ</p>
      </div>
      <div class="contauner_all_projects">
        <div
          v-for="(project, index) in projects"
          :key="index"
          :id="'project-' + index"
          class="box_projects_animated"
        >
          <div class="box_projects">
            <div class="box_hover_img_projects">
              <div class="box_main_text_projects">
                <p
                  v-for="(text, idx) in project.mainText.split(' ')"
                  :key="idx"
                  class="medium_text"
                  v-html="text"
                ></p>
              </div>
              <div class="box_statistics_projects_hover">
                <p class="title_services_design">
                  <span class="semi_big_text color_violet">{{
                    project.statistics.title_span
                  }}</span>
                  <br />
                  {{ project.statistics.title }}
                </p>
                <p class="title_services_design">
                  <span class="big_text color_violet">{{
                    project.statistics.years
                  }}</span>
                  <span v-html="project.statistics.text"></span>
                </p>
              </div>
              <div class="box_href_arrow_projects">
                <div class="href_services_design_arrow">
                  <img
                    class="arrow_href_img"
                    src="@/assets/elements/arrow_href.svg"
                  />
                </div>
              </div>
              <div class="box_main_text_projects">
                <p class="medium_text">{{ project.secondText }}</p>
              </div>
              <img class="hover_img_projects" :src="project.illustration" />
              <!--  :src="project.illustration"
              src="@/assets/background/yycare.png"-->
              <img
                class="hover_img_projects_weavs"
                src="@/assets/background/weves_hover.svg"
              />
            </div>
            <!-- :src="project.weavsImg.src"
            :src="project.hoverImg.src" -->
            <div class="box_text_hover">
              <p class="subtext_services_design">{{ project.subText }}</p>
              <p class="title_services_design">
                {{ project.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="box_button_circle">
        <router-link to="all_project" class="button_circle">
          Больше<br />
          проектов
          <div class="underlining_button"></div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import { gsap } from "gsap";
import yycareImage from "@/assets/background/yycare.png";
import logisticsImage from "@/assets/background/logistics.svg";
import AladariImage from "@/assets/background/Aladari.png";
import SevenStarImage from "@/assets/background/SevenStar.png";
import CottagesImage from "@/assets/background/Cottages.png";
import VatamanyukPartnersImage from "@/assets/background/VatamanyukPartners.png";

export default {
  data() {
    return {
      projects: [
        {
          mainText: "Backend UX/UI&nbsp;Дизайн Frontend Аналитика QA ",
          statistics: {
            title_span: "7000+",
            title: "сотрудников",
            years: "28",
            text: "лет<br />на рынке",
          },
          illustration: logisticsImage,
          secondText: "Современные логистические решения",

          subText: "Торговый дом «Шкуренко» Логистический комплекс",
          title: "Корпоративный портал для управления логистикой",
        },
        {
          mainText: "Frontend SEO UX/UI&nbsp;Дизайн Аналитика QA Backend",
          statistics: {
            title_span: "1200+",
            title: "Клиентов",
            years: "1 ",
            text: "год<br />на рынке",
          },
          illustration: yycareImage,
          secondText: "Цифровая платформа",

          subText: "YourYogaCare",
          title: "Инновационный онлайн-сервис для занятий йогой",
        },
        {
          mainText: "Backend Аналитика QA Frontend UX/UI&nbsp;Дизайн",
          statistics: {
            title_span: "200+",
            title: "торговых точек",
            years: "2 ",
            text: "года<br />на рынке",
          },
          illustration: AladariImage,
          secondText: "Промо-сервис",

          subText: "Торговый дом «Шкуренко» Aladari",
          title: "Онлайн-платформа для продвижения и рекламных кампаний",
        },
        {
          mainText: "Frontend UX/UI&nbsp;Дизайн SEO Backend",
          statistics: {
            title_span: "38+",
            title: "товаров в каталоге",
            // years: "5",
            // text: "лет<br />на рынке",
          },
          illustration: SevenStarImage,
          secondText: "Вейп-продукция",
          subText: "SevenStar",
          title: "Современный сайт-бренд с каталогом продукции",
        },
        {
          mainText: "Frontend UX/UI&nbsp;Дизай Аналитика Backend  ",
          statistics: {
            title_span: "12",
            title: "вариантов домов",
            years: "2 ",
            text: "года<br />на рынке",
          },
          illustration: CottagesImage,
          secondText: "Коттеджи от застройщика",
          subText: "Коттеджы «Пушкино»",
          title: "Сайт для продажи коттеджей с вариативным каталогом",
        },
        {
          mainText:
            "Frontend Backend UX/UI&nbsp;Дизайн SEO Интеграция Аналитика QA",
          statistics: {
            title_span: "15+",
            title: "лет опыта",
            years: "11 ",
            text: "лет<br />на рынке",
          },
          illustration: VatamanyukPartnersImage, // Убедитесь, что переменная с изображением определена
          secondText: "Юридическое сопровождение бизнеса",
          subText: "Ватаманюк & Партнеры",
          title: "Сайт юридической компании с комплексными услугами",
        },
      ],
    };
  },
  mounted() {
    const boxProjectsElements = document.querySelectorAll(
      ".box_projects_animated"
    );

    // Анимация для каждого блока
    boxProjectsElements.forEach((targetElement) => {
      const tl = gsap.timeline({ paused: true });

      const onIntersection = (entries) => {
        if (entries[0].isIntersecting) {
          tl.play();
          targetElement.removeEventListener("intersection", onIntersection);
        }
      };

      const observer = new IntersectionObserver(onIntersection, {
        threshold: 0.1,
      });

      observer.observe(targetElement);

      tl.set(targetElement, { y: "15rem", opacity: 0 });
      tl.to(targetElement, { y: "0rem", opacity: 1, duration: 1 });
    });
  },
};
</script>
<style scoped>
.box_projects_animated {
  /* Добавьте стили для начального состояния, если нужно */
  opacity: 0;
}
.box_main_text_projects {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  row-gap: 0.5rem;
}
.semi_big_text {
  font-size: 2.75rem;
}
</style>
