<template>
  <section class="section_experience">
    <div class="box_very_big_text">
      <p class="title_services_design">
        <span class="very_big_text color_violet">50 +</span>
        <br />
        проектов реализованно
      </p>
    </div>
    <div class="box_experience_small">
      <p class="title_services_design box_experience_small_1">
        <span class="big_text color_violet">15</span>
        <br />
        Сотрудников<br />
        в шатате
      </p>
      <p class="title_services_design box_experience_small_2">
        <span class="big_text color_violet">6</span>
        лет<br />
        на рынке
      </p>

      <!-- <p class="title_services_design box_experience_small_3">
        <span class="big_text color_violet">10</span>
        лет<br />
        на рынке
      </p>
      <p class="title_services_design box_experience_small_4">
        <span class="big_text color_violet">10</span>
        лет<br />
        на рынке
      </p> -->
    </div>
  </section>
</template>
<script>
import { gsap } from "gsap";

export default {
  mounted() {
    const sectionExperienceElement = document.querySelector(
      ".section_experience"
    );
    const tl = gsap.timeline({ paused: true });

    const onIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        tl.play();
        sectionExperienceElement.removeEventListener(
          "intersection",
          onIntersection
        );
      }
    };

    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0.1,
    });

    observer.observe(sectionExperienceElement);

    // Анимация для box_very_big_text
    const targetElement1 = document.querySelector(".box_very_big_text");
    tl.set(targetElement1, { y: "5rem", opacity: 0 });
    tl.to(targetElement1, { y: "0rem", opacity: 1, duration: 1 });

    // Анимация для box_experience_small блоков
    for (let i = 1; i <= 4; i++) {
      const targetElement = document.querySelector(
        `.box_experience_small_${i}`
      );
      tl.set(targetElement, { y: "15rem", opacity: 0 }, "<+0.3"); // Задержка 0.1 секунды
      tl.to(targetElement, { y: "0rem", opacity: 1, duration: 0.2 }, "<");
    }
  },
};
</script>
