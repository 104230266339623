<template>
  <HeaderPages />
  <section class="section_article">
    <div class="container_article_left">
      <div class="main_block_article">
        <p class="font_1rem_400_Raleway color_violet">18 ноября 2024</p>
        <h2 class="title_services_design">
          Как интернет-магазин под ключ увеличит ваши продажи
        </h2>
        <img
          class="illustrations_articles"
          src="@/assets/article_illustrations/turnkey.webp"
        />
        <div class="autor_mobile">
          <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
          <!-- <div class="all_autor_article">
              <img
                class="img_autor_article_small"
                src="@/assets/elements/img_autor_article.svg"
              />
              <div class="box_text_all_autor_article">
                <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
                <p class="font_1_400_Kreadon_Regular color_violet">
                  Frontend-разработчик
                </p>
              </div>
            </div> -->
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
        <h1 class="font_1_6_500_Raleway">
          Интернет-магазин под ключ: решение для увеличения ваших продаж
        </h1>
        <p class="font_1_2_500_Raleway">
          Создание интернет-магазина под ключ становится всё более популярным
          среди предпринимателей, которые хотят вывести свои продажи на новый
          уровень. Этот подход позволяет получить готовый к работе инструмент,
          не требующий дополнительных доработок, что экономит время и ресурсы. В
          статье мы рассмотрим основные преимущества интернет-магазина под ключ
          и расскажем, как он помогает увеличить продажи.
        </p>
      </div>
      <p class="font_1_6_500_Raleway">Что такое интернет-магазин под ключ?</p>
      <h2 class="font_700 font_1rem_400_Raleway">
        Полный цикл создания интернет-магазина
      </h2>
      <div class="box_text_articles">
        <p class="font_1rem_400_Raleway font_500">
          Интернет-магазин под ключ — это готовое решение, которое включает:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>Разработку дизайна, адаптированного под ваш бренд.</li>
          <li>Создание удобного и интуитивного интерфейса.</li>
          <li>Интеграцию с платёжными системами и службами доставки.</li>
          <li>SEO-оптимизацию для привлечения трафика.</li>
          <li>Настройку аналитики и интеграцию с CRM.</li>
        </ul>
        <p class="font_0_9_400_Raleway font_500">
          Такой подход позволяет вам сосредоточиться на ведении бизнеса, а не на
          технических деталях.
        </p>
      </div>

      <p class="font_1_6_500_Raleway">
        Как интернет-магазин под ключ помогает увеличить продажи?
      </p>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">1.Удобство для клиентов</h2>

        <p class="font_1rem_400_Raleway font_500">
          Удобный и функциональный интерфейс интернет-магазина привлекает
          пользователей и помогает им быстро находить нужные товары. Что это
          даёт:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Снижение времени на поиск информации.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Увеличение числа завершённых покупок.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Положительный пользовательский опыт, стимулирующий возврат
              клиентов
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.SEO-оптимизация для увеличения трафика
        </h2>

        <p class="font_1rem_400_Raleway font_500">
          Правильно настроенный интернет-магазин легко продвигается в поисковых
          системах:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              SEO-оптимизация помогает привлечь органический трафик.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Удобная структура сайта улучшает ранжирование в поиске.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Контент с ключевыми словами увеличивает видимость.
            </p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Интеграция с платёжными и аналитическими системами
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Интернет-магазин под ключ интегрируется с популярными платёжными
          системами и аналитическими инструментами, что даёт:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Удобство оплаты для клиентов.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Отслеживание покупательской активности.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Анализ конверсий для повышения продаж.
            </p>
          </li>
        </ul>

        <h2 class="font_700 font_1rem_400_Raleway">4.Поддержка и развитие</h2>
        <p class="font_1rem_400_Raleway font_500">
          Готовое решение под ключ предполагает поддержку и доработку
          интернет-магазина:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Регулярные обновления функционала.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Добавление новых модулей и расширений.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Обеспечение бесперебойной работы.
            </p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">
          Преимущества интернет-магазина под ключ
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          1.Экономия времени и ресурсов
        </h2>
        <p class="font_0_9_400_Raleway">
          Вы получаете полностью готовый инструмент, с которым можно сразу
          начинать работу. Вам не нужно заниматься техническими вопросами или
          искать отдельных специалистов.
        </p>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.Гибкость и масштабируемость
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Интернет-магазин под ключ можно адаптировать под ваши нужды:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Добавлять новые категории товаров.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Настраивать акции и программы лояльности.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Масштабировать проект в зависимости от роста бизнеса.
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          3.Эффективное продвижение
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Оптимизированный интернет-магазин привлекает больше клиентов через:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">SEO.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Рекламу в социальных сетях.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Контекстную рекламу.</p>
          </li>
        </ul>
      </div>
      <div class="box_text_articles">
        <p class="font_1_6_500_Raleway">Как выбрать подходящее решение?</p>
        <h2 class="font_700 font_1rem_400_Raleway">
          1.Учитывайте специфику вашего бизнеса
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Определите, что важно для вашего интернет-магазина:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">Количество товаров.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Наличие интеграций (CRM, 1С, платёжные системы).
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Требования к дизайну и функционалу.
            </p>
          </li>
        </ul>
        <h2 class="font_700 font_1rem_400_Raleway">
          2.Выбирайте профессиональную разработку
        </h2>
        <p class="font_1rem_400_Raleway font_500">
          Обратитесь к специалистам, которые предлагают комплексные услуги:
        </p>
        <ul class="list_articles font_0_9_400_Raleway">
          <li>
            <p class="font_0_9_400_Raleway">
              Индивидуальный подход к разработке.
            </p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">Гарантии качества и сроков.</p>
          </li>
          <li>
            <p class="font_0_9_400_Raleway">
              Техническую поддержку после запуска.
            </p>
          </li>
        </ul>
      </div>
      <div class="box_wave_articles">
        <h2 class="font_1_2_500_Raleway">
          Готовый интернет-магазин: шаг к увеличению продаж
        </h2>
        <p class="font_0_9_400_Raleway">
          Интернет-магазин под ключ — это эффективное решение для бизнеса,
          которое помогает увеличить продажи, привлечь новых клиентов и
          упростить управление процессами. Воспользуйтесь этим инструментом,
          чтобы сделать ваш бизнес более успешным и конкурентоспособным.
        </p>
        <img
          class="wave_small_articles"
          src="@/assets/background/wave_small_articles.svg"
        />
      </div>
    </div>
    <div class="container_article_right">
      <p class="font_1_400_Kreadon_Regular color_violet">Авторы</p>
      <div class="box_autor_article">
        <div class="autor_article_first">
          <img
            class="img_autor_article"
            src="@/assets/elements/img_autor_article.svg"
          />
          <button @click="toggleModal" class="button_open_autor_article">
            <img
              :src="isModalOpen ? closeImageSrc : openImageSrc"
              class="img_button_open_autor_article"
            />
          </button>
        </div>
        <!-- <div class="autor_article_first m_autor_article">
            <img
              class="img_autor_article"
              src="@/assets/elements/img_autor_article.svg"
            />
            <button @click="toggleModal" class="button_open_autor_article">
              <img
                :src="isModalOpen ? closeImageSrc : openImageSrc"
                class="img_button_open_autor_article"
              />
            </button>
          </div> -->
        <div ref="box_all_autor_article" class="box_all_autor_article">
          <div class="all_autor_article">
            <img
              class="img_autor_article_small"
              src="@/assets/elements/img_autor_article.svg"
            />
            <div class="box_text_all_autor_article">
              <p class="font_1_400_Kreadon_Regular">Немчицкий Кирилл</p>
              <p class="font_1_400_Kreadon_Regular color_violet">
                SEO-специалист
              </p>
            </div>
          </div>
        </div>
      </div>
      <p class="title_services_design">Подпишись на наши соцсети</p>
      <p class="font_1_400_Kreadon_Regular color_violet">
        Мы отправляем полезные материалы, которые помогут вам в работе. Мы
        отправляем полезные материалы, которые помогут вам в работе
      </p>
      <div class="container_button_networks_articles">
        <div class="box_button_networks_articles">
          <button class="button_v1">Telegram</button>
        </div>
        <div class="box_button_networks_articles">
          <button class="button_v1">Youtube</button>
        </div>
      </div>
    </div>
  </section>
  <section class="section_article_bottom">
    <div class="container_article_bottom">
      <div class="box_back_article">
        <button class="button_back_article">
          <img
            src="@/assets/elements/arrow_left.svg"
            class="arrow_left_article"
          />
        </button>
        <p class="text_small_kreadon-bold color_violet">
          вернуться к списку статей
        </p>
      </div>
    </div>
  </section>
  <FooterArticles />
</template>
<script>
import { useHead } from "@vueuse/head";
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterArticles from "@/components/permanent/FooterArticles.vue";

export default {
  components: { HeaderPages, FooterArticles },
  data() {
    return {
      isModalOpen: false, // Состояние модального окна
      openImageSrc: require("@/assets/elements/arrow_bottom_white.svg"),
      closeImageSrc: require("@/assets/elements/arrow_top_white.svg"),
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen; // Переключение состояния
      this.$refs.box_all_autor_article.classList.toggle("active");
    },
    closeModal() {
      this.isModalOpen = false; // Сброс состояния
      this.$refs.box_all_autor_article.classList.remove("active");
    },
  },
  setup() {
    useHead({
      title:
        "Интернет-магазин под ключ: как он увеличит продажи и привлечет клиентов",
      meta: [
        {
          name: "description",
          content:
            "Узнайте, как интернет-магазин под ключ поможет вашему бизнесу увеличить продажи, привлечь новых клиентов и оптимизировать процессы. Полный цикл разработки: от идеи до запуска и продвижения.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.font_500 {
  font-weight: 500 !important;
}
</style>
