<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">УСЛУГИ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button data-action="toggleBig" class="button_v1">
            Оставить заявку
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="sectio_services_pages">
    <div class="container_services_pages_main">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <h2 class="title_services_design">
              Разработка сайтов и веб-сервисов
            </h2>
            <p class="subtext_services_design">
              Полный спектр услуг по созданию сайтов: от разработки
              корпоративных порталов и интернет-магазинов до одностраничников и
              сложных веб-приложений. Удобный интерфейс и высокая
              производительность гарантированы.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>Корпоративные сайты и порталы</li>
          <li>Интернет-магазины на 1С Битрикс</li>
          <li>Сайты-каталоги и одностраничники</li>
          <li>Мобильные версии сайтов</li>
          <li>Разработка сайтов на Tilda и WordPress</li>
        </ul>
        <!-- <ul class="list_services subtext_services_design">
          <li>Корпоративные сайты</li>
          <li>Онлайн-сервисы</li>
          <li>E-commerce</li>
          <li>Порталы</li>
          <li>Карьерные сайты</li>
        </ul> -->
      </div>
      <!-- <button class="button_blog">Подробнее</button> -->
    </div>
    <div class="container_services_pages_main bg_blue">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <h2 class="title_services_design">
              UI/UX Дизайн и Фирменный стиль
            </h2>
            <p class="subtext_services_design">
              Создаем уникальный дизайн с акцентом на пользовательский опыт и
              конверсию. Разрабатываем фирменный стиль, логотипы и графику для
              брендов, учитывая современные тренды.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>UX/UI дизайн</li>
          <li>Дизайн-системы и прототипы</li>
          <li>Брендинг и логотипы</li>
        </ul>
        <ul class="list_services subtext_services_design">
          <li>3D-визуализация и графический дизайн</li>
          <li>Редизайн сайта и фирменного стиля</li>
          <li>Дизайн мобильных приложений</li>
        </ul>
      </div>
      <!-- <button class="button_blog">Подробнее</button> -->
    </div>
    <div class="container_services_pages_main">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <h2 class="title_services_design">Комплексная аналитика и аудит</h2>
            <p class="subtext_services_design">
              Проведем полный аудит вашего сайта, исследуем юзабилити и
              конкурентов, предложим рекомендации по улучшению и стратегии
              продвижения.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>UX-аналитика и юзабилити аудит</li>
          <li>Технический аудит сайта</li>
          <li>Исследования рынка и конкурентов</li>
        </ul>
      </div>
      <!-- <button class="button_blog">Подробнее</button> -->
    </div>
    <div class="container_services_pages_main bg_blue">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <h2 class="title_services_design">SEO-оптимизация и продвижение</h2>
            <p class="subtext_services_design">
              Комплексное SEO для вывода сайта в топ поисковых систем,
              привлечение органического трафика и повышение конверсии.
              Оптимизируем как новые сайты, так и существующие проекты.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>SEO-оптимизация на этапе разработки</li>
          <li>Продвижение молодого сайта</li>
          <li>Региональное и локальное SEO</li>
        </ul>
        <ul class="list_services subtext_services_design">
          <li>SEO для интернет-магазинов</li>
          <li>Комплексный аудит и внутренняя оптимизация</li>
          <li>SEO на Tilda и WordPress</li>
        </ul>
      </div>
      <!-- <button class="button_blog">Подробнее</button> -->
    </div>
    <div class="container_services_pages_main">
      <div class="container_services_pages">
        <div class="box_title_services_pages">
          <div class="box_text_services_pages">
            <h2 class="title_services_design">
              Техническая поддержка и обслуживание
            </h2>
            <p class="subtext_services_design">
              Обеспечиваем бесперебойную работу вашего сайта, выполняем
              регулярное обновление контента и модернизацию функционала.
            </p>
          </div>
        </div>
        <ul class="list_services subtext_services_design">
          <li>Базовая техническая поддержка</li>
          <li>Обслуживание и доработка сайта</li>
          <li>Интеграция с CRM и автоматизация</li>
        </ul>
      </div>
      <!-- <button class="button_blog">Подробнее</button> -->
    </div>
  </section>
  <section class="section_schemes">
    <p class="title_services_design color_violet">СХЕМЫ РАБОТЫ</p>
    <div class="container_schemes">
      <div class="box_schemes margin_schemes">
        <p class="title_services_design pos_schemes_v1">Fix Price</p>
        <img src="@/assets/background/Fix_Price.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v2">
          Для задач с предсказуемыми<br />
          сроком и бюджетом.
        </p>
      </div>
      <div class="box_schemes">
        <p class="title_services_design pos_schemes_v1">Time & materials</p>
        <img src="@/assets/background/Time.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v3">
          Гибкий подход для<br />
          сложных проектов с<br />
          изменяющимися требованиями.
        </p>
      </div>
      <div class="box_schemes margin_schemes">
        <p class="title_services_design pos_schemes_v1">Fix Price</p>
        <img src="@/assets/background/Retainer.png" class="img_schemes" />
        <p class="subtext_services_design pos_schemes_v3">
          Выделенная команда<br />
          на длительный срок с<br />
          фиксированной стоимостью услуг.
        </p>
      </div>
    </div>
  </section>
  <SectionBlog />
  <!-- <SectionClients /> -->
  <FooterPages />
</template>
<script>
import HeaderPages from "@/components/permanent/HeaderPages.vue";
import FooterPages from "@/components/permanent/FooterPages.vue";
import SectionBlog from "@/components/ui/SectionBlog.vue";
// import SectionClients from "@/components/ui/SectionClients.vue";
import { useHead } from "@vueuse/head";
export default {
  components: {
    HeaderPages,
    SectionBlog,
    // SectionClients,
    FooterPages,
  },
  setup() {
    useHead({
      title:
        "Услуги по разработке сайтов, UX/UI дизайну, SEO-оптимизации и технической поддержке",
      meta: [
        {
          name: "description",
          content:
            "Комплексные услуги: создание сайтов, разработка веб-приложений, UX/UI дизайн, SEO-продвижение, аудит и поддержка. Эффективные цифровые решения для вашего бизнеса.",
        },
      ],
    });
  },
};
</script>
