<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">НАШИ<br /></span>
        <span class="title_home_1 color_violet"> ПРОЕКТЫ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Создаем удобные и эффективные сайты, которые нравятся пользователя и
        хорошо продвигаются в поисковых системах.
      </p>
    </div>
  </section>
  <section class="section_advantages_services">
    <div class="container_advantages">
      <p class="medium_text text_position_advantages_services">
        Глубокая аналитика
      </p>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Конкуренты, лучшие решения,<br />
        бизнес-интервью,<br />
        веб-аналитика, CJM и SEO
      </p>
    </div>
    <div class="container_advantages">
      <p class="medium_text text_position_advantages_services">
        Гибкие методологии<br />
        Agile
      </p>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Гарантируют прозрачность<br />
        всего процесса разработки
      </p>
    </div>
    <div class="container_advantages">
      <p class="medium_text text_position_advantages_services">
        Управление вниманием
      </p>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Конкуренты, лучшие решения,<br />
        бизнес-интервью,<br />
        веб-аналитика, CJM и SEO
      </p>
    </div>
    <div class="container_advantages">
      <p class="medium_text text_position_advantages_services">
        Сокращение<br />
        time to market
      </p>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Конкуренты, лучшие решения,<br />
        бизнес-интервью,<br />
        веб-аналитика, CJM и SEO
      </p>
    </div>
  </section>
  <section class="section_solutions">
    <p class="title_services_design color_violet">РЕШЕНИЯ</p>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <!-- Стрелка отображается только если есть текст -->
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <!-- Текстовый блок, который может быть скрыт, если текст отсутствует -->
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section_development_technologies">
    <p class="title_services_design color_violet">МЫ ИСПОЛЬЗВУЕМ ТЕХНОЛОГИИ</p>
    <div class="container_development_technologies">
      <div class="box_development_technologies">
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>PHP</li>
          <li>1С-Битрикс</li>
          <li>Битрикс24</li>
          <li>CI/CD</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>Redis</li>
          <li>Rabbit</li>
          <li>Highload</li>
          <li>Git</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>composer</li>
          <li>migrations</li>
          <li>ООП</li>
          <li>Laravel</li>
        </ul>
      </div>
      <div class="box_development_technologies">
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>HTML5</li>
          <li>CSS3 (Sass/SCSS)</li>
          <li>БЭМ</li>
          <li>JavaScript (es6+)</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>React</li>
          <li>Vue</li>
          <li>Webpack</li>
          <li>Gulp</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>NPM</li>
          <li>SVG</li>
          <li>Canvas</li>
          <li>WebGL</li>
        </ul>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "Корпоративные сайты",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
        {
          title: "Онлайн-сервисы",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
        {
          title: "Е-commerce",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
        {
          title: "Порталы",
          text: null, // Здесь текста нет, стрелка и возможность клика будут отключены
        },
        {
          title: "Карьерные сайты",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
        {
          title: "Системы сайтов",
          text: null, // Здесь текста нет, стрелка и возможность клика будут отключены
        },
        {
          title: "Продуктовые каталоги",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
        {
          title: "Промо-сайты",
          text: null, // Здесь текста нет, стрелка и возможность клика будут отключены
        },
        {
          title: "No-code",
          text: `Большой опыт исследований позволяет нам создавать решения, 
                 которые повышают инвестиционную привлекательность и удовлетворяют 
                 все типы целевых аудиторий: инвесторы, партнеры, клиенты., СМИ, 
                 госорганы и сотрудники.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];

      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
};
</script>
